import { memo, useCallback, useState } from 'react';
import {
  ListItem,
  Grid,
  G600,
  IconButton,
  Divider,
  CloseIcon,
  E600,
  PromptModal,
  useTranslations,
  useHeroSnackbar,
  TypographyWithTooltip,
} from '@uniqkey-frontend/shared-app';
import { GroupLatestOrganizationsForAdminResponseModel } from '@uniqkey-backend-partner/api-client';
import { useNavigate } from 'react-router-dom';
import { logException } from '../../../../../../../../services/sentryService';
import { useRemoveOrganizationsFromGroup } from '../../../../../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../../../../../enums/PageRouteEnum';
import {
  ORGANIZATION_ACCESS_STATUS_COLORS,
  ORGANIZATION_ACCESS_STATUS_TRANSLATION_KEYS,
  ORGANIZATION_ACCESS_STATUS_TOOLTIP_TRANSLATION_KEYS,
} from '../../../../../../../../constants';

interface IAddOrganizationForAdminToGroupWidgetListItemProps {
  organization: GroupLatestOrganizationsForAdminResponseModel;
  groupId: string;
}

const ICON_BUTTON_SX = { padding: 0 };
const GRID_SX = { cursor: 'pointer' };

const AddOrganizationForAdminToGroupWidgetListItem = (
  props: IAddOrganizationForAdminToGroupWidgetListItemProps,
) => {
  const { organization, groupId } = props;
  const { name, organizationId, organizationAccessStatus } = organization;
  const navigate = useNavigate();

  const { t } = useTranslations();
  const { showError, showSuccess } = useHeroSnackbar();
  const {
    mutate: mutateRemoveOrganizationFromGroup,
    isLoading: isRemoveOrganizationFromGroupLoading,
  } = useRemoveOrganizationsFromGroup({ isForAdmin: true });

  const [
    isRemoveOrganizationFromGroupModalOpen,
    setIsRemoveOrganizationFromGroupModalOpen,
  ] = useState(false);
  const handleRemoveOrganizationFromGroupModalOpen = useCallback(
    () => setIsRemoveOrganizationFromGroupModalOpen(true),
    [],
  );
  const handleRemoveOrganizationFromGroupModalClose = useCallback(
    () => setIsRemoveOrganizationFromGroupModalOpen(false),
    [],
  );

  const handleRemoveOrganizationFromGroup = useCallback(async () => {
    mutateRemoveOrganizationFromGroup(
      { groupId, organizationIds: [organizationId] },
      {
        onSuccess: () => {
          showSuccess({ text: t('removeOrganizationFromGroupModal.successMessage') });
          handleRemoveOrganizationFromGroupModalClose();
        },
        onError: (e) => {
          showError({ text: t('removeOrganizationFromGroupModal.errorMessage') });
          logException(e, {
            message:
                'AddOrganizationForAdminToGroupWidget/handleRemoveOrganizationFromGroup exception',
          });
        },
      },
    );
  }, [
    mutateRemoveOrganizationFromGroup,
    handleRemoveOrganizationFromGroupModalClose,
    groupId,
    organizationId,
    showError,
    showSuccess,
    t,
  ]);

  const handleNavigate = useCallback(() => {
    navigate(`${PageRouteEnum.Groups}/${groupId}/${organizationId}`);
  }, [groupId, navigate, organizationId]);

  return (
    <>
      <ListItem>
        <Grid item container>
          <Grid item container justifyContent="space-between" wrap="nowrap" mt={1}>
            <Grid item xs sx={GRID_SX} onClick={handleNavigate}>
              <TypographyWithTooltip variant="body2" color={G600} cursorPointer breakWord>
                {name}
              </TypographyWithTooltip>
            </Grid>
            <TypographyWithTooltip
              variant="body2"
              title={t(
                ORGANIZATION_ACCESS_STATUS_TOOLTIP_TRANSLATION_KEYS[organizationAccessStatus],
              )}
              color={ORGANIZATION_ACCESS_STATUS_COLORS[organizationAccessStatus]}
            >
              {t(ORGANIZATION_ACCESS_STATUS_TRANSLATION_KEYS[organizationAccessStatus])}
            </TypographyWithTooltip>
            <IconButton
              disabled={isRemoveOrganizationFromGroupLoading}
              onClick={handleRemoveOrganizationFromGroupModalOpen}
              sx={ICON_BUTTON_SX}
            >
              <CloseIcon color={E600} width={16} height={16} />
            </IconButton>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Divider />
          </Grid>
        </Grid>
      </ListItem>
      {isRemoveOrganizationFromGroupModalOpen && (
        <PromptModal
          open={isRemoveOrganizationFromGroupModalOpen}
          onClose={handleRemoveOrganizationFromGroupModalClose}
          onSubmit={handleRemoveOrganizationFromGroup}
          title={t('removeOrganizationFromGroupModal.title')}
          description={t('removeOrganizationFromGroupModal.description', { name })}
          approvalButtonText="common.remove"
          isLoading={isRemoveOrganizationFromGroupLoading}
        />
      )}
    </>
  );
};

export default memo(AddOrganizationForAdminToGroupWidgetListItem);
