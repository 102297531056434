import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import {
  useHeroSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TSetSearchParams,
  IUseFilterButtonReturn,
  buildTablePreserverDecoderConfigItem,
  TablePreserverDecoderConfigItemTypeEnum,
  useFilterButton,
  TablePreserverCommonInitialTableValues,
} from '@uniqkey-frontend/shared-app';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import { IAuditLogsTableRow } from '../../../components/tables/AuditLogsTable';
import {
  IGetAuditLogsParams,
  TGetAuditLogsMethod,
} from '../../useAuditLogsAPI/interfaces';
import useAuditLogsAPI from '../../useAuditLogsAPI';
import {
  IAuditLogsListFilterSubmitResult,
} from '../../../pages/AuditLogsPage/components/AuditLogsTab/components/AuditLogsListFilter';

export const REACT_QUERY_AUDIT_LOGS_KEY = [ReactQueryKeyEnum.AuditLogs];

const AUDIT_LOGS_REACT_QUERY_CACHE_TIME = 30 * 1000;

export interface IUseAuditLogsTableParams {
  noDataMessageKey: string;
}

export interface IUseAuditLogsTableReturn extends
  Omit<
    IUseTableReturn<IAuditLogsTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'selectedRows' | 'resetTableMethods'
  >,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseQueryTableFetchReturn<TGetAuditLogsMethod>, 'data' | 'isFetchedAfterMount'>,
  IUseFilterButtonReturn
{
  auditLogs: IUseQueryTableFetchReturn<TGetAuditLogsMethod>['data'];
  selectedAuditLogs: IUseTableReturn<IAuditLogsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  filterValues: IAuditLogsListFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IAuditLogsListFilterSubmitResult>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetAuditLogsParams,
    'page' | 'pageLength' | 'partnerId' | 'partnerUserId' | 'beforeDate' | 'afterDate'
  >
{
  activePage: IGetAuditLogsParams['page'];
  perPage: IGetAuditLogsParams['pageLength'];
  partnerUser: IAuditLogsListFilterSubmitResult['partnerUser'];
  dateRange: IAuditLogsListFilterSubmitResult['dateRange'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  partnerUser: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.OBJECT,
  ),
  dateRange: true,
};

const useAuditLogsTable = (params: IUseAuditLogsTableParams): IUseAuditLogsTableReturn => {
  const {
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getAuditLogs } = useAuditLogsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    partnerUser: initialPartnerUser,
    dateRange: initialDateRange,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [filterValues, setFilterValues] = useState<
    IAuditLogsListFilterSubmitResult
  >({
    partnerUser: initialPartnerUser,
    dateRange: initialDateRange,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    selectedRows: selectedAuditLogs,
    resetTableMethods,
    ...restTableProps
  } = useTable<IAuditLogsTableRow>({
    initialActivePage,
    initialPerPage,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setFilterValues({
      partnerUser: undefined,
      dateRange: TablePreserverCommonInitialTableValues.dateRange,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: auditLogs, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_AUDIT_LOGS_KEY,
    queryClient,
    request: getAuditLogs,
    params: {
      page: activePage,
      pageLength: perPage,
      partnerUserId: filterValues.partnerUser?.value,
      afterDate: filterValues.dateRange?.from ?? undefined,
      beforeDate: filterValues.dateRange?.to ?? undefined,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
    useQueryOptions: {
      cacheTime: AUDIT_LOGS_REACT_QUERY_CACHE_TIME,
    },
  });

  const { localization } = useTableLocalization({
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    partnerUser: {
      value: filterValues.partnerUser,
      parser: JSON.stringify,
    },
    dateRange: {
      value: filterValues.dateRange,
      parser: JSON.stringify,
    },
  });

  return {
    auditLogs,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    selectedAuditLogs,
    localization,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    ...restTableProps,
  };
};

export default useAuditLogsTable;
