import { useMemo, memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useHeroSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { useGetPartnerById, useGetOrganizationById } from '../../../../hooks/reactQuery';

interface IPartnerOrganizationPageBreadcrumbsProps {
  partnerId: string;
  organizationId: string;
}

const PartnerOrganizationPageBreadcrumbs = (props: IPartnerOrganizationPageBreadcrumbsProps) => {
  const {
    partnerId, organizationId,
  } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useHeroSnackbar();

  const {
    data: partner,
    isLoading: isPartnerLoading,
    isError: isPartnerError,
  } = useGetPartnerById(
    { partnerId },
  );

  const {
    data: organization,
    isLoading: isOrganizationLoading,
    isError: isOrganizationError,
  } = useGetOrganizationById(
    { organizationId },
  );

  const isLoading = isPartnerLoading || isOrganizationLoading;

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !partner || !organization) {
      return [];
    }
    return [
      {
        title: t('navigation.partners'),
        onClick: () => navigate(PageRouteEnum.Partners),
      },
      {
        title: partner.name,
        onClick: () => navigate(`${PageRouteEnum.Partners}/${partner.partnerId}`),
      },
      { title: organization.name },
    ];
  }, [isLoading, partner, organization, navigate, t]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isPartnerError) {
      showError({ text: t('common.somethingWentWrong') });
      navigate(PageRouteEnum.Partners);
      return;
    }
    if (isOrganizationError) {
      showError({ text: t('common.somethingWentWrong') });
      navigate(`${PageRouteEnum.Partners}/${partnerId}`);
    }
  }, [isLoading, isPartnerError, isOrganizationError, partnerId, navigate, showError, t]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(PartnerOrganizationPageBreadcrumbs);
