import { useCallback, memo, useMemo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  usePubSub,
  useHeroSnackbar,
  ReactHookFormCheckbox,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  GetExtendedOrganizationsResponseModel,
  GetPartnersForLookupResponseModel,
  GetPartnersMovedFromForLookupResponseModel,
} from '@uniqkey-backend-partner/api-client';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import {
  IGetExtendedOrganizationsParams,
} from '../../../../hooks/usePartnerOrganizationsAPI/interfaces';
import { COUNTRIES_LIST } from '../../../../helpers/countries';
import usePartnersAPI from '../../../../hooks/usePartnersAPI';
import { logException } from '../../../../services/sentryService';
import usePartnerOrganizationsAPI from '../../../../hooks/usePartnerOrganizationsAPI';

export interface IOrganizationsExtendedListFilterSubmitResult {
  countryFilter: IGetExtendedOrganizationsParams['countryFilter'];
  partner: TPartnerOption | undefined;
  movedFromPartner: TMovedFromPartnerOption | undefined;
  allMovedPartners: IGetExtendedOrganizationsParams['allMovedPartners'];
  showSoftDeleted: IGetExtendedOrganizationsParams['showSoftDeleted'];
}

type TPartnerOption = {
  value: GetPartnersForLookupResponseModel['partnerId'];
  label: GetPartnersForLookupResponseModel['partnerName'];
} | null

type TMovedFromPartnerOption = {
  value: GetPartnersMovedFromForLookupResponseModel['partnerId'];
  label: GetPartnersMovedFromForLookupResponseModel['partnerName'];
} | null

interface IOrganizationsExtendedListFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IOrganizationsExtendedListFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IOrganizationsExtendedListFilterSubmitResult;
}

interface IFormValues {
  countryFilter: GetExtendedOrganizationsResponseModel['country'] | null;
  partner: TPartnerOption;
  movedFromPartner: TMovedFromPartnerOption;
  allMovedPartners: IGetExtendedOrganizationsParams['allMovedPartners'] | null;
  showSoftDeleted: IGetExtendedOrganizationsParams['showSoftDeleted'];
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const ALL_ORGANIZATIONS_VALUE = 'All moved organizations';

const OrganizationsExtendedListFilter = (props: IOrganizationsExtendedListFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues,
  } = props;
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getPartnersForLookup } = usePartnersAPI();
  const { getExtendedOrganizationsMovedFromForLookup } = usePartnerOrganizationsAPI();

  const allOrganizationsOption = useMemo(() => ({
    value: ALL_ORGANIZATIONS_VALUE,
    label: t('organizationsExtendedFilter.allOrganizations'),
  }), [t]);

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      countryFilter: initialValues.countryFilter ?? null,
      partner: initialValues.partner ?? null,
      /*
        'movedFromPartner' is handled by 'allMovedPartners' field
        If it is true, 'movedFromPartner' should have 'allOrganizationsOption' option
      */
      movedFromPartner: initialValues.allMovedPartners
        ? allOrganizationsOption
        : (initialValues.movedFromPartner ?? null),
      allMovedPartners: initialValues.allMovedPartners ?? null,
      showSoftDeleted: initialValues.showSoftDeleted ?? false,
    },
  });

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const {
      countryFilter, partner, movedFromPartner, showSoftDeleted,
    } = value;
    const processedMovedFromPartner = movedFromPartner?.value === ALL_ORGANIZATIONS_VALUE
      ? undefined
      : (movedFromPartner ?? undefined); // if no value - use 'undefined' instead of 'null'
    onSubmit({
      countryFilter: countryFilter ?? undefined,
      partner: partner ?? undefined,
      movedFromPartner: processedMovedFromPartner,
      allMovedPartners: movedFromPartner?.value === ALL_ORGANIZATIONS_VALUE ? true : undefined,
      showSoftDeleted: showSoftDeleted || undefined,
    });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const handleGetExtendedOrganizationsMovedFromForLookupRequest = useCallback(async (
    searchQuery: string,
  ) => {
    try {
      const { data } = await getExtendedOrganizationsMovedFromForLookup(
        { page: 1, pageLength: 100, searchQuery },
      );
      return data;
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      return [];
    }
  }, [getExtendedOrganizationsMovedFromForLookup, showError, t]);

  const handleGetExtendedOrganizationsMovedFromForLookupResponseParser = useCallback((
    partners: GetPartnersMovedFromForLookupResponseModel[],
  ) => {
    const processedPartners = partners.map(
      (partner) => ({
        value: partner.partnerId,
        label: partner.partnerName,
      }),
    );
    return [allOrganizationsOption, ...processedPartners];
  }, [allOrganizationsOption]);

  const handleGetPartnersForLookupRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getPartnersForLookup({ page: 1, pageLength: 100, searchQuery });
      return data;
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'OrganizationsExtendedListFilter/handleGetPartnersForLookupRequest exception',
      });
      return [];
    }
  }, [getPartnersForLookup, showError, t]);

  const handleGetPartnersForLookupParser = useCallback(
    (partnersToParse: GetPartnersForLookupResponseModel[]) => partnersToParse.map(
      (partner) => ({
        value: partner.partnerId,
        label: partner.partnerName,
      }),
    ),
    [],
  );

  const clearAll = useCallback(() => {
    setValue('countryFilter', null, { shouldDirty: true });
    setValue('partner', null, { shouldDirty: true });
    setValue('movedFromPartner', null, { shouldDirty: true });
    setValue('allMovedPartners', null, { shouldDirty: true });
    setValue('showSoftDeleted', false, { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      countryFilter: null,
      partner: null,
      movedFromPartner: null,
      allMovedPartners: null,
      showSoftDeleted: false,
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationsExtendedFilter.country.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="countryFilter"
                  placeholder={t('organizationsExtendedFilter.country.placeholder')}
                  options={COUNTRIES_LIST}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationsExtendedFilter.partner.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  t={t}
                  name="partner"
                  placeholder={t('organizationsExtendedFilter.partner.placeholder')}
                  dataSourceRequest={handleGetPartnersForLookupRequest}
                  dataSourceResponseParser={handleGetPartnersForLookupParser}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationsExtendedFilter.movedFromPartner.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  t={t}
                  name="movedFromPartner"
                  placeholder={t('organizationsExtendedFilter.movedFromPartner.placeholder')}
                  dataSourceRequest={handleGetExtendedOrganizationsMovedFromForLookupRequest}
                  dataSourceResponseParser={
                    handleGetExtendedOrganizationsMovedFromForLookupResponseParser
                  }
                  control={control}
                  fetchOnEmptyQuery
                />
              </Grid>
            </Grid>
            <Grid item>
              <ReactHookFormCheckbox
                name="showSoftDeleted"
                control={control}
                label={(
                  <Typography>
                    {t('organizationsExtendedFilter.showSoftDeleted.label')}
                  </Typography>
                )}
              />
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(OrganizationsExtendedListFilter);
