import { useCallback, memo, useState } from 'react';
import {
  useHeroSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { GetOrganizationsResponseModel } from '@uniqkey-backend-partner/api-client';
import { useQueryClient } from 'react-query';
import { logException } from '../../../../services/sentryService';
import useGroupsAPI from '../../../../hooks/useGroupsAPI';
import { resetGroupOrganizationsRelatedQueries } from '../../../../hooks/reactQuery';
import EntitySelectorModal, {
  TEntitySelectorModalOnSubmit,
} from '../../../../components/EntitySelectorModal';

interface IGroupOrganizationsSelectorModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupId: string;
  isForAdmin: boolean;
}

interface IParsedEntity {
  id: GetOrganizationsResponseModel['organizationId'];
  label: string;
}

const GroupOrganizationsSelectorModal = (props: IGroupOrganizationsSelectorModalProps) => {
  const {
    isOpen,
    onClose,
    groupId,
    isForAdmin,
  } = props;

  const { t } = useTranslations();
  const { showSuccess, showError } = useHeroSnackbar();
  const { getGroupOrganizationsForLookup } = useGroupsAPI();
  const { addOrganizationsToGroup } = useGroupsAPI();
  const queryClient = useQueryClient();

  const [
    isGroupOrganizationsSelectorModalLoading,
    setIsGroupOrganizationsSelectorModalLoading,
  ] = useState(false);

  const handleAutocompleteRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getGroupOrganizationsForLookup({
        page: 1, pageLength: 100, groupId, searchQuery,
      });
      return data;
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupOrganizationsSelectorModal/handleAutocompleteRequest exception',
      });
      return [];
    }
  }, [getGroupOrganizationsForLookup, groupId, showError, t]);

  const handleSubmitEntitySelectorModal = useCallback<
    TEntitySelectorModalOnSubmit<IParsedEntity[]>
  >(async (value) => {
    try {
      const organizationIds = value.map((el: { id: string }) => el.id);
      setIsGroupOrganizationsSelectorModalLoading(true);
      await addOrganizationsToGroup({ organizationIds, groupId });
      showSuccess({
        text: t(
          'addOrganizationsToGroupModal.organizationAddedToGroup',
          { count: organizationIds.length },
        ),
      });
      onClose();
      resetGroupOrganizationsRelatedQueries(queryClient, groupId, isForAdmin);
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupOrganizationsSelectorModal/handleSubmitEntitySelectorModal exception',
      });
    } finally {
      setIsGroupOrganizationsSelectorModalLoading(false);
    }
  }, [
    addOrganizationsToGroup,
    groupId,
    onClose,
    isForAdmin,
    queryClient,
    showError,
    showSuccess,
    t,
  ]);

  const handleResponseEntitySelectorModal = useCallback(
    (organizationToParse: GetOrganizationsResponseModel[]) => organizationToParse.map(
      (organization: GetOrganizationsResponseModel) => ({
        id: organization.organizationId,
        label: `${organization.name}, ${organization.vat}`,
      }),
    ),
    [],
  );

  return (
    <EntitySelectorModal
      isOpen={isOpen}
      title={t('addOrganizationsToGroupModal.title')}
      placeholder={t('addOrganizationsToGroupModal.selectOrganizationsPlaceholder')}
      description={t('addOrganizationsToGroupModal.description')}
      onSubmit={handleSubmitEntitySelectorModal}
      request={handleAutocompleteRequest}
      responseParser={handleResponseEntitySelectorModal}
      onClose={onClose}
      isLoading={isGroupOrganizationsSelectorModalLoading}
    />
  );
};

export default memo(GroupOrganizationsSelectorModal);
