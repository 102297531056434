import {
  useTranslations,
  ReactHookFormAutocomplete,
  useHeroSnackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  S600,
  Typography,
  ScrollableBox,
} from '@uniqkey-frontend/shared-app';
import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  GetPartnersForLookupResponseModel,
  OrganizationsMoveRequest,
} from '@uniqkey-backend-partner/api-client';
import usePartnersAPI from '../../../../hooks/usePartnersAPI';
import {
  IOrganizationsExtendedTableRow,
} from '../../../../hooks/tables/useOrganizationsExtendedTable';

export interface IMoveOrganizationsModalSubmitResult extends Pick<
  OrganizationsMoveRequest, 'targetPartnerId'
> {}

interface IMoveOrganizationsModalFormValue {
  targetPartner: TTargetPartnerOption;
}

type TTargetPartnerOption = {
  label: string;
  value: string;
} | null;

interface IMoveOrganizationsModalProps {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  onSubmit: (params: IMoveOrganizationsModalSubmitResult) => Promise<void> | void;
  selectedOrganizationsAsObjects: IOrganizationsExtendedTableRow[];
}

const SCROLLABLE_BOX_SX = { height: '100%', maxHeight: 190 };
const UNIQKEY = 'UniqKey';

const MoveOrganizationsModal = (props: IMoveOrganizationsModalProps) => {
  const {
    open,
    onClose,
    selectedOrganizationsAsObjects,
    isLoading,
    onSubmit,
  } = props;
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getPartnersForLookup } = usePartnersAPI();

  const {
    handleSubmit, control, formState: { isDirty },
  } = useForm<IMoveOrganizationsModalFormValue>({
    defaultValues: {
      targetPartner: null,
    },
  });

  const handleGetPartnersForLookupRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getPartnersForLookup({
        page: 1,
        pageLength: 100,
        searchQuery,
      });
      return data;
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      return [];
    }
  }, [getPartnersForLookup, showError, t]);

  const handleGetPartnersForLookupResponseParser = useCallback((
    partners: GetPartnersForLookupResponseModel[],
  ) => partners
    .filter(({ partnerName }) => partnerName !== UNIQKEY)
    .map((organization) => ({
      value: organization.partnerId,
      label: organization.partnerName,
    })), []);

  const handleFormSubmit = useCallback((formValue: IMoveOrganizationsModalFormValue): void => {
    const { targetPartner } = formValue;
    onSubmit({
      targetPartnerId: targetPartner!.value,
    });
  }, [onSubmit]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off" noValidate>
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('moveOrganizationsModal.title', { count: selectedOrganizationsAsObjects.length })}
        </DialogTitle>
        <DialogContent>
          <Grid container flexDirection="column" rowGap={2}>
            <Grid item>
              <Typography>
                {t('moveOrganizationsModal.description')}
              </Typography>
            </Grid>
            <Grid item xs>
              <ScrollableBox sx={SCROLLABLE_BOX_SX}>
                {selectedOrganizationsAsObjects.map((organization) => (
                  <Typography noWrap color={S600} key={organization.organizationId}>
                    {organization.name}
                  </Typography>
                ))}
              </ScrollableBox>
            </Grid>
            <Grid item>
              <Typography>
                {t('moveOrganizationsModal.additionalDescription')}
              </Typography>
            </Grid>
            <Grid item>
              <ReactHookFormAutocomplete
                name="targetPartner"
                control={control}
                t={t}
                placeholder={t('moveOrganizationsModal.targetPartner.placeholder')}
                dataSourceRequest={handleGetPartnersForLookupRequest}
                dataSourceResponseParser={handleGetPartnersForLookupResponseParser}
                fetchOnEmptyQuery
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                type="submit"
                disabled={!isDirty}
                isLoading={isLoading}
                fullWidth
              >
                {t('common.move')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={onClose} disabled={isLoading} variant="outlined" fullWidth>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(MoveOrganizationsModal);
