import { memo, useCallback, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  getCitiesByCountryName,
  Grid,
  maxLengthValidator,
  ReactHookFormAutocomplete,
  G500,
  Typography,
  useTranslations,
  validateAddress,
  validateEmail,
  validatePhone,
  validateVAT,
  validateZIPCode,
  FormHiddenInput,
  MenuItem,
  S600,
  ReactHookFormSelect,
  emptyStringValidator,
  ReactHookFormTextField,
  TSelectProps,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { CreatePartnerRequest, PartnerType } from '@uniqkey-backend-partner/api-client';
import PartnerTypeSelect from '../PartnerTypeSelect';
import { PARTNER_TYPE_TO_OPTIONS } from '../../../../helpers/partnerType';
import { TPartnerTypeOption } from '../../../../helpers/partnerType/interfaces';
import { COUNTRIES_LIST } from '../../../../helpers/countries';
import { useLanguages } from '../../../../hooks/reactQuery';

export interface ICreatePartnerModalFormValue extends Omit<
  CreatePartnerRequest, 'type' | 'country' | 'city'
> {
  type: TPartnerTypeOption;
  country: CreatePartnerRequest['country'] | null;
  city: CreatePartnerRequest['city'] | null;
}

interface ICreatePartnerModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (params: CreatePartnerRequest) => Promise<void> | void;
  onClose: () => void;
  type: PartnerType;
}

const CreatePartnerModal = (props: ICreatePartnerModalProps) => {
  const {
    isOpen, isLoading, onSubmit, onClose, type,
  } = props;

  const { t } = useTranslations();
  const { languages, isLoading: areLanguagesLoading } = useLanguages({
    includeOnlyAllowedLanguages: false,
  });

  const {
    handleSubmit,
    formState: { isDirty, errors, touchedFields },
    control,
    setValue,
    watch,
  } = useForm<ICreatePartnerModalFormValue>({
    defaultValues: {
      name: '',
      vatNumber: '',
      type: PARTNER_TYPE_TO_OPTIONS[type as keyof typeof
        PARTNER_TYPE_TO_OPTIONS]?.defaultValue ?? '',
      country: null,
      city: null,
      address: '',
      zip: '',
      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonPhone: '',
      firstPartnerEmail: '',
      firstPartnerName: '',
      firstPartnerLanguage: 'da',
    },
    mode: 'all',
  });

  const [
    selectedCountry,
  ] = watch([
    'country',
  ]);

  const citiesOptions = useMemo(() => (selectedCountry
    ? getCitiesByCountryName(selectedCountry)
    : []
  ), [selectedCountry]);

  const handleFormSubmit = useCallback((formValue: ICreatePartnerModalFormValue) => {
    onSubmit(formValue as CreatePartnerRequest);
  }, [onSubmit]);

  const handleCountryChange = useCallback(() => {
    setValue('city', null);
  }, [setValue]);

  const handleLanguageChange = useCallback<
    NonNullable<TSelectProps['onChange']>
  >((event) => {
    // the 'Create' button should be enabled directly after the language change, not after blur
    setValue('firstPartnerLanguage', event.target.value as string, { shouldTouch: true });
  }, [setValue]);

  const disabled = !isDirty && !touchedFields.firstPartnerLanguage;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off" noValidate>
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('createPartnerModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color={G500}>
                <span>{t('createPartnerModal.subTitles.invitePartner')}</span>
                <span>:</span>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="firstPartnerName"
                control={control}
                fullWidth
                autoComplete="none"
                autoFocus
                label={`${t('createPartnerModal.firstAdminName.label')}*`}
                placeholder={t('createPartnerModal.firstAdminName.placeholder')}
                error={!!errors.firstPartnerName}
                helperText={errors.firstPartnerName?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value as string) ? t('validation.required') : true
                  ),
                }}
              />
              <FormHiddenInput />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="firstPartnerEmail"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createPartnerModal.firstAdminEmail.label')}*`}
                placeholder={t('createPartnerModal.firstAdminEmail.placeholder')}
                error={!!errors.firstPartnerEmail}
                helperText={errors.firstPartnerEmail?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateEmail(v as string) || t('validation.invalidEmail'),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color={G500}>
                <span>{t('createPartnerModal.subTitles.partnerDetails')}</span>
                <span>:</span>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="name"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createPartnerModal.partnerName.label')}*`}
                placeholder={t('createPartnerModal.partnerName.placeholder')}
                error={!!errors.name}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value as string) ? t('validation.required') : true
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <PartnerTypeSelect
                control={control}
                name="type"
                type={type}
                label={`${t('createPartnerModal.type.label')}*`}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormAutocomplete
                t={t}
                name="country"
                onChange={handleCountryChange}
                options={COUNTRIES_LIST}
                control={control}
                label={`${t('createPartnerModal.country.label')}*`}
                placeholder={t('createPartnerModal.country.placeholder')}
                error={!!errors.country}
                helperText={errors.country?.message}
                disableAutoFill
                rules={{ required: t('validation.required') }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormAutocomplete
                t={t}
                name="city"
                freeSolo
                options={citiesOptions}
                control={control}
                label={`${t('createPartnerModal.city.label')}*`}
                placeholder={t('createPartnerModal.city.placeholder')}
                error={!!errors.city}
                helperText={errors.city?.message}
                disableAutoFill
                disabled={!selectedCountry}
                rules={{
                  validate: {
                    isValid: (v) => validateAddress(v as string) || t('validation.invalidCity'),
                    required: (v) => (selectedCountry && !v ? t('validation.required') : true),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="address"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createPartnerModal.address.label')}*`}
                placeholder={t('createPartnerModal.address.placeholder')}
                error={!!errors.address}
                helperText={errors.address?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateAddress(v as string)
                        || t('validation.invalidAddress'),
                    required: (value) => (emptyStringValidator(value as string)
                      ? t('validation.required')
                      : true),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="zip"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createPartnerModal.zipCode.label')}*`}
                placeholder={t('createPartnerModal.zipCode.placeholder')}
                error={!!errors.zip}
                helperText={errors.zip?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateZIPCode(v as string)
                        || t('validation.invalidZIPCode'),
                    maxLength: (v) => maxLengthValidator(12, v as string)
                        || t('validation.maxLength', { length: 12 }),
                    required: (value) => (emptyStringValidator(value as string)
                      ? t('validation.required')
                      : true),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="vatNumber"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createPartnerModal.vat.label')}*`}
                placeholder={t('createPartnerModal.vat.placeholder')}
                error={!!errors.vatNumber}
                helperText={errors.vatNumber?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateVAT(v as string)
                        || t('validation.invalidVAT'),
                    maxLength: (v) => maxLengthValidator(20, v as string)
                        || t('validation.maxLength', { length: 20 }),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color={G500}>
                <span>{t('createPartnerModal.subTitles.contactPersonDetails')}</span>
                <span>:</span>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="contactPersonName"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createPartnerModal.contactName.label')}*`}
                placeholder={t('createPartnerModal.contactName.placeholder')}
                error={!!errors.contactPersonName}
                helperText={errors.contactPersonName?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value as string) ? t('validation.required') : true
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="contactPersonEmail"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createPartnerModal.contactEmail.label')}*`}
                placeholder={t('createPartnerModal.contactEmail.placeholder')}
                error={!!errors.contactPersonEmail}
                helperText={errors.contactPersonEmail?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateEmail(v as string) || t('validation.invalidEmail'),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="contactPersonPhone"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createPartnerModal.phone.label')}*`}
                placeholder={t('createPartnerModal.phone.placeholder')}
                error={!!errors.contactPersonPhone}
                helperText={errors.contactPersonPhone?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validatePhone(v as string)
                        || t('validation.invalidPhone'),
                    maxLength: (v) => maxLengthValidator(19, v as string)
                        || t('validation.maxLength', { length: 19 }),
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2}>
            {
              !areLanguagesLoading && (
                <Grid container item alignItems="center" xs>
                  <Typography variant="body1" color={S600} mr={1}>
                    <span>{t('createPartnerModal.languages.label')}</span>
                    <span>:</span>
                  </Typography>
                  <ReactHookFormSelect
                    name="firstPartnerLanguage"
                    control={control}
                    onChange={handleLanguageChange}
                  >
                    {
                      languages.map((currLanguage) => (
                        <MenuItem key={currLanguage.code} value={currLanguage.code}>
                          {currLanguage.localized_name}
                        </MenuItem>
                      ))
                    }
                  </ReactHookFormSelect>
                </Grid>
              )
            }
            <Grid item xs={2.5}>
              <Button fullWidth isLoading={isLoading} disabled={disabled} type="submit">
                {t('common.create')}
              </Button>
            </Grid>
            <Grid item xs={2.5}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(CreatePartnerModal);
