import {
  Grid,
  Typography,
  G600,
  TypographyWithTooltip,
  G900,
  useTranslations,
  WidgetContainer,
} from '@uniqkey-frontend/shared-app';
import { GetOrganizationSettingsInfoResponse } from '@uniqkey-backend-partner/api-client';
import { memo } from 'react';

interface IDeletionActionDetailsWidgetProps extends Pick<GetOrganizationSettingsInfoResponse,
  'archivedByPartnerUserName' | 'archivedByPartnerUserEmail'> {}

const DeletionActionDetailsWidget = (props: IDeletionActionDetailsWidgetProps) => {
  const { archivedByPartnerUserName, archivedByPartnerUserEmail } = props;
  const { t } = useTranslations();

  return (
    <WidgetContainer
      container
      flexDirection="column"
      py={3}
      withShadow
      rowGap={3}
    >
      <Grid container item>
        <Typography variant="subtitle1" color={G900}>
          {t('organization.settings.delete.action.title')}
        </Typography>
      </Grid>
      <Grid container item flexDirection="column" rowGap={1}>
        <Typography variant="caption" color={G600}>
          {t('organization.settings.delete.action.name.label')}
        </Typography>
        <TypographyWithTooltip variant="body3" color={G900} breakWord>
          {archivedByPartnerUserName}
        </TypographyWithTooltip>
      </Grid>
      <Grid container item flexDirection="column" rowGap={1}>
        <Typography variant="caption" color={G600}>
          {t('organization.settings.delete.action.email.label')}
        </Typography>
        <TypographyWithTooltip variant="body3" color={G900} breakWord>
          {archivedByPartnerUserEmail}
        </TypographyWithTooltip>
      </Grid>
    </WidgetContainer>
  );
};

export default memo(DeletionActionDetailsWidget);
