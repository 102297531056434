import {
  useCallback,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useHeroSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { OrganizationVerifiedDomainsProperty } from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import usePartnerOrganizationsAPI from '../../usePartnerOrganizationsAPI';
import {
  IGetOrganizationVerifiedDomainsParams,
  TGetOrganizationVerifiedDomainsMethod,
} from '../../usePartnerOrganizationsAPI/interfaces';
import {
  IOrganizationVerifiedDomainsTableRow,
} from '../../../components/tables/OrganizationVerifiedDomainsTable';
import { ORGANIZATION_IS_IN_RETENTION_PERIOD_ERROR } from '../../../constants';

export const REACT_QUERY_ORGANIZATION_VERIFIED_DOMAINS_KEY = [
  ReactQueryKeyEnum.OrganizationVerifiedDomains,
];

interface IPersistentFilters extends Pick<
  IGetOrganizationVerifiedDomainsParams, 'organizationId'
> {}

export interface IUseOrganizationVerifiedDomainsTableParams {
  persistentFilters: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseOrganizationVerifiedDomainsTableReturn extends
  Omit<IUseTableReturn<IOrganizationVerifiedDomainsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<
    IUseQueryTableFetchReturn<TGetOrganizationVerifiedDomainsMethod>,
    'data' | 'isFetchedAfterMount'
  >
{
  organizationVerifiedDomains: IUseQueryTableFetchReturn<
    TGetOrganizationVerifiedDomainsMethod
  >['data'];
  selectedOrganizationVerifiedDomains: IUseTableReturn<
    IOrganizationVerifiedDomainsTableRow
  >['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetOrganizationVerifiedDomainsParams,
    'page' | 'pageLength' | 'organizationId' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<
    IOrganizationVerifiedDomainsTableRow
  >, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetOrganizationVerifiedDomainsParams['page'];
  perPage: IGetOrganizationVerifiedDomainsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: OrganizationVerifiedDomainsProperty },
  ),
  columnOrderDirection: true,
};

const useOrganizationVerifiedDomainsTable = (
  params: IUseOrganizationVerifiedDomainsTableParams,
): IUseOrganizationVerifiedDomainsTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getOrganizationVerifiedDomains } = usePartnerOrganizationsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback((err: any) => {
    let key = 'common.somethingWentWrong';
    if (err?.response?.data?.includes(ORGANIZATION_IS_IN_RETENTION_PERIOD_ERROR)) {
      key = 'toasts.organizationIsInRetentionPeriod';
    }
    showError({ text: t(key) });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedOrganizationVerifiedDomains,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationVerifiedDomainsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: organizationVerifiedDomains, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_ORGANIZATION_VERIFIED_DOMAINS_KEY,
    queryClient,
    request: getOrganizationVerifiedDomains,
    params: {
      page: activePage,
      pageLength: perPage,
      orderPropertyName: OrganizationVerifiedDomainsProperty[
        upperFirst(columnOrderBy) as keyof typeof OrganizationVerifiedDomainsProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    organizationVerifiedDomains,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedOrganizationVerifiedDomains,
    localization,
    resetActivePage,
    ...restTableProps,
  };
};

export default useOrganizationVerifiedDomainsTable;
