import { useCallback, useState } from 'react';
import {
  G900,
  Box,
  Button,
  ClosedEyeIcon,
  EyeIcon,
  Grid,
  IconButton,
  InputAdornment,
  HeroLogoWithTextIcon,
  minLengthValidator,
  Panel,
  ReactHookFormTextField,
  G600,
  stringsMatchValidator,
  Typography,
  useHeroSnackbar,
  useTranslations,
  validateDigit,
  validateLowerCaseLetter,
  validateSpecialCharacter,
  validateUpperCaseLetter,
  ValidationGroup,
  ValidationItem,
  BaseEitherError,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { MeResponse } from '@uniqkey-backend-partner/api-client';
import { logException } from '../../services/sentryService';
import { setupMasterPassword } from '../../services/authService';
import { useUser } from '../../contexts/UserContext';
import UnauthenticatedStepHelperSection from '../../components/UnauthenticatedStepHelperSection';
import KeysManager from '../../services/keysManager';
import { isAdmin } from '../../helpers/userRole';

interface ISetupMasterPasswordFormValue {
  masterPassword: string;
  confirmMasterPassword: string;
}

const MIN_WIDTH = 720;
const MIN_HEIGHT = 726;

const SetupMasterPasswordPage = () => {
  const { t } = useTranslations();
  const { showSuccess, showError } = useHeroSnackbar();
  const { currentUser } = useUser();
  const { id, role } = currentUser ?? {} as MeResponse;

  const [isSetupMasterPasswordLoading, setIsSetupMasterPasswordLoading] = useState(false);
  const [isMasterPasswordShown, setIsMasterPasswordShown] = useState(false);
  const [isConfirmMasterPasswordShown, setIsConfirmMasterPasswordShown] = useState(false);

  const {
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
    trigger,
    control,
  } = useForm<ISetupMasterPasswordFormValue>({
    criteriaMode: 'all',
    mode: 'all',
    defaultValues: {
      confirmMasterPassword: '',
      masterPassword: '',
    },
  });

  const toggleMasterPasswordShown = useCallback(
    () => setIsMasterPasswordShown(!isMasterPasswordShown),
    [isMasterPasswordShown],
  );

  const toggleConfirmMasterPasswordShown = useCallback(
    () => setIsConfirmMasterPasswordShown(!isConfirmMasterPasswordShown),
    [isConfirmMasterPasswordShown],
  );

  const handleConfirmMasterPasswordChange = useCallback(() => {
    trigger();
  }, [trigger]);

  const handleOnSubmit = useCallback(async (form: ISetupMasterPasswordFormValue) => {
    try {
      setIsSetupMasterPasswordLoading(true);
      // do not set 'isLoading' to 'false' in the try-block so the submit button remains disabled
      const { masterPassword: password } = form;
      await setupMasterPassword({ id, password });
      const setupPartnerUserKeysResult = await KeysManager
        .PartnerUserKeys.setupPartnerUserKeysOperation({ userId: id, password });
      if (setupPartnerUserKeysResult instanceof BaseEitherError) {
        logException(setupPartnerUserKeysResult, {
          message: 'SetupMasterPasswordPage/handleOnSubmit/setupPartnerUserKeysOperation exception',
        });
        // show a success message regardless of the result
        showSuccess({ text: t('setupMasterPasswordPage.passwordCreatedToast') });
        return;
      }
      if (isAdmin(role)) {
        const {
          privateKey: partnerUserPrivateKey,
          publicKey: partnerUserPublicKey,
        } = setupPartnerUserKeysResult;
        const setupPartnerKeysResult = await KeysManager.PartnerKeys.setupPartnerKeysOperation({
          partnerUserPrivateKey,
          partnerUserPublicKey,
        });
        if (setupPartnerKeysResult instanceof BaseEitherError) {
          logException(setupPartnerKeysResult, {
            message: 'SetupMasterPasswordPage/handleOnSubmit/setupPartnerKeysOperation exception',
          });
          // do not return here so the showSuccess below will be shown
        }
      }
      // show a success message regardless of the result
      showSuccess({ text: t('setupMasterPasswordPage.passwordCreatedToast') });
    } catch (e) {
      setIsSetupMasterPasswordLoading(false);
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'SetupMasterPasswordPage/handleOnSubmit exception',
      });
    }
  }, [showError, showSuccess, id, role, t]);

  return (
    <Grid
      container
      className="min-height-100-vh"
      alignItems="center"
      justifyContent="center"
    >
      <Box my={5}>
        <Panel>
          <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete="off">
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              flexDirection="column"
              rowGap={8}
              p={4}
              minWidth={MIN_WIDTH}
              minHeight={MIN_HEIGHT}
            >
              <Grid item container alignItems="center" justifyContent="center">
                <HeroLogoWithTextIcon height={60} width={210} />
              </Grid>
              <Grid item>
                <Typography color={G900}>
                  {t('setupMasterPasswordPage.title')}
                </Typography>
              </Grid>
              <Grid item container alignItems="center" justifyContent="center" rowGap={3}>
                <Grid item container xs={9}>
                  <ReactHookFormTextField
                    control={control}
                    id="new_password"
                    fullWidth
                    autoFocus
                    name="masterPassword"
                    type={isMasterPasswordShown ? 'text' : 'password'}
                    error={!!errors.masterPassword}
                    label={t('setupMasterPasswordPage.createMasterPassword.label')}
                    placeholder={t('setupMasterPasswordPage.createMasterPassword.placeholder')}
                    rules={{
                      validate: {
                        specialCharacter: (password) => validateSpecialCharacter(password),
                        digit: (password) => validateDigit(password),
                        upperCaseLetter: (password) => validateUpperCaseLetter(password),
                        lowerCaseLetter: (password) => validateLowerCaseLetter(password),
                        minLength: (password) => minLengthValidator(12, password),
                        passwordsMatch: (password) => stringsMatchValidator(
                          getValues().confirmMasterPassword,
                          password,
                          { caseSensitive: true },
                        ),
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={toggleMasterPasswordShown}
                          >
                            {isMasterPasswordShown ? <ClosedEyeIcon /> : <EyeIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item container xs={9}>
                  <ReactHookFormTextField
                    id="password_confirmation"
                    name="confirmMasterPassword"
                    onChange={handleConfirmMasterPasswordChange}
                    control={control}
                    fullWidth
                    type={isConfirmMasterPasswordShown ? 'text' : 'password'}
                    error={!!errors.confirmMasterPassword}
                    label={t('setupMasterPasswordPage.confirmMasterPassword.label')}
                    placeholder={t('setupMasterPasswordPage.confirmMasterPassword.placeholder')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={toggleConfirmMasterPasswordShown}
                          >
                            {isConfirmMasterPasswordShown ? <ClosedEyeIcon /> : <EyeIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                rowGap={2}
              >
                <Grid item container alignItems="center" justifyContent="center">
                  <Typography variant="subtitle1" color={G600}>
                    {t('setupMasterPasswordPage.validationAreaDescription')}
                  </Typography>
                </Grid>
                <Grid item xs={4.5} />
                <Grid item xs={5}>
                  <ValidationGroup alignItems="center" justifyContent="flex-start">
                    <ValidationItem
                      isDirty={isDirty}
                      isValid={!errors.masterPassword?.types?.minLength}
                    >
                      {t('setupMasterPasswordPage.includeMinimumDigits')}
                    </ValidationItem>
                    <ValidationItem
                      isDirty={isDirty}
                      isValid={
                      !errors.masterPassword?.types?.upperCaseLetter
                      && !errors.masterPassword?.types?.lowerCaseLetter
                    }
                    >
                      {t('setupMasterPasswordPage.includeUppercaseAndLowercaseLetters')}
                    </ValidationItem>
                    <ValidationItem
                      isDirty={isDirty}
                      isValid={!errors.masterPassword?.types?.digit}
                    >
                      {t('setupMasterPasswordPage.includeNumbers')}
                    </ValidationItem>
                    <ValidationItem
                      isValid={!errors.masterPassword?.types?.specialCharacter}
                      isDirty={isDirty}
                    >
                      {t('setupMasterPasswordPage.includeSymbols')}
                    </ValidationItem>
                    <ValidationItem
                      isValid={!errors.masterPassword?.types?.passwordsMatch}
                      isDirty={isDirty}
                    >
                      {t('setupMasterPasswordPage.passwordsMatch')}
                    </ValidationItem>
                  </ValidationGroup>
                </Grid>
                <Grid item xs={2.5} />
              </Grid>
              <Grid item container xs={4}>
                <Button
                  isLoading={isSetupMasterPasswordLoading}
                  type="submit"
                  fullWidth
                  disabled={!isDirty || !!errors.masterPassword}
                >
                  {t('common.confirm')}
                </Button>
              </Grid>
              <UnauthenticatedStepHelperSection />
            </Grid>
          </form>
        </Panel>
      </Box>
    </Grid>
  );
};

export default SetupMasterPasswordPage;
