import { useCallback } from 'react';
import {
  useHeroSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetOrganizationEmployeeAccountAuditLogsParams,
  TGetOrganizationEmployeeAccountAuditLogsMethod,
} from '../../useEmployeeAccountsAPI/interfaces';
import useEmployeeAccountsAPI from '../../useEmployeeAccountsAPI';
import {
  IOrganizationEmployeeAuditLogsTableRow,
} from '../../../components/tables/OrganizationEmployeeAuditLogsTable';

export const REACT_QUERY_ORGANIZATION_EMPLOYEE_AUDIT_LOGS_KEY = [
  ReactQueryKeyEnum.OrganizationEmployeeAuditLogs,
];

interface IPersistentFilters extends Pick<
  IGetOrganizationEmployeeAccountAuditLogsParams, 'employeeAccountId'
> {}

export interface IUseOrganizationEmployeeAuditLogsTableParams {
  persistentFilters: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseOrganizationEmployeeAuditLogsTableReturn extends
  Omit<
    IUseTableReturn<IOrganizationEmployeeAuditLogsTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'selectedRows' | 'resetTableMethods'
  >,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<
    IUseQueryTableFetchReturn<TGetOrganizationEmployeeAccountAuditLogsMethod>,
    'data' | 'isFetchedAfterMount'
  >
{
  auditLogs: IUseQueryTableFetchReturn<TGetOrganizationEmployeeAccountAuditLogsMethod>['data'];
  selectedAuditLogs: IUseTableReturn<IOrganizationEmployeeAuditLogsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetOrganizationEmployeeAccountAuditLogsParams,
    'employeeAccountId' | 'page' | 'pageLength' | 'beforeDate' | 'afterDate'
  >
{
  activePage: IGetOrganizationEmployeeAccountAuditLogsParams['page'];
  perPage: IGetOrganizationEmployeeAccountAuditLogsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
};

const useOrganizationEmployeeAuditLogsTable = (
  params: IUseOrganizationEmployeeAuditLogsTableParams,
): IUseOrganizationEmployeeAuditLogsTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getOrganizationEmployeeAccountAuditLogs } = useEmployeeAccountsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    selectedRows: selectedAuditLogs,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationEmployeeAuditLogsTableRow>({
    initialActivePage,
    initialPerPage,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: auditLogs, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_ORGANIZATION_EMPLOYEE_AUDIT_LOGS_KEY,
    queryClient,
    request: getOrganizationEmployeeAccountAuditLogs,
    params: {
      page: activePage,
      pageLength: perPage,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
  });

  return {
    auditLogs,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    selectedAuditLogs,
    localization,
    resetActivePage,
    ...restTableProps,
  };
};

export default useOrganizationEmployeeAuditLogsTable;
