import {
  useCallback, memo, useMemo, SyntheticEvent,
} from 'react';
import {
  Button,
  Grid,
  Typography,
  useTranslations,
  ReactHookFormAutocomplete,
  Divider,
  getFromYearOptions,
  getFromMonthOptions,
  getToYearOptions,
  getToMonthOptions,
  ReactHookFormCheckbox,
  useHeroSnackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  ExportOrganizationLicenseDataRequest,
  GetOrganizationsForLookupResponseModel,
} from '@uniqkey-backend-partner/api-client';
import usePartnerOrganizationsAPI from '../../hooks/usePartnerOrganizationsAPI';
import { IOrganizationsTableRow } from '../../hooks/tables/useOrganizationsTable';

export interface IDownloadCSVModalSubmitResult extends ExportOrganizationLicenseDataRequest {}

interface IDownloadCSVModalFormValue extends Omit<
  ExportOrganizationLicenseDataRequest, 'organizationIds' | 'exportPeriod'
>{
  organizations: IOrganizationOption[];
  fromYear: TDateOption;
  fromMonth: TDateOption;
  toYear: TDateOption;
  toMonth: TDateOption;
}

interface IDownloadCSVModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (params: IDownloadCSVModalSubmitResult) => Promise<void> | void;
  onClose: () => void;
  selectedOrganizations?: Map<string, IOrganizationsTableRow>;
  organizationId?: string;
  partnerId?: string;
}

type TDateOption = {
  label: string;
  value: number;
} | null;

interface IOrganizationOption {
  value: string;
  label: string;
}

const FORM_STYLE = { width: '100%' };

const ALL_ORGANIZATIONS_VALUE = 'All organizations';

const DownloadCSVModal = (props: IDownloadCSVModalProps) => {
  const {
    isOpen, isLoading, onSubmit, onClose, selectedOrganizations, organizationId, partnerId,
  } = props;
  const { t, currentLanguage } = useTranslations();
  const { getOrganizationsForLookup } = usePartnerOrganizationsAPI();
  const { showError } = useHeroSnackbar();

  const processedSelectedOrganizations = useMemo(
    () => {
      if (!selectedOrganizations) return [];
      return Array.from(selectedOrganizations.values()).map(
        (organization) => (
          { value: organization.organizationId, label: organization.name }),
      );
    },
    [selectedOrganizations],
  );

  const {
    handleSubmit, control, setValue, watch,
  } = useForm<IDownloadCSVModalFormValue>({
    defaultValues: {
      fromYear: null,
      fromMonth: null,
      toYear: null,
      toMonth: null,
      allTime: false,
      organizations: processedSelectedOrganizations,
      allOrganizations: false,
    },
  });

  const {
    fromYear: selectedFromYear,
    fromMonth: selectedFromMonth,
    toYear: selectedToYear,
    toMonth: selectedToMonth,
    allTime: selectedAllTime,
    organizations: watchOrganizations,
    allOrganizations: selectedAllOrganizations,
  } = watch();

  const allOrganizationsOption = useMemo(() => ({
    value: ALL_ORGANIZATIONS_VALUE,
    label: t('downloadCSVModal.allOrganizations'),
  }), [t]);

  const handleGetOrganizationsForLookupRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getOrganizationsForLookup({
        page: 1, pageLength: 100, partnerId, searchQuery,
      });
      return data;
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      return [];
    }
  }, [getOrganizationsForLookup, partnerId, showError, t]);

  const handleGetOrganizationsForLookupResponseParser = useCallback((
    organizations: GetOrganizationsForLookupResponseModel[],
  ) => {
    const processedOrganizations = organizations.map(
      (organization) => ({
        value: organization.organizationId,
        label: organization.organizationName,
      }),
    );
    return [allOrganizationsOption, ...processedOrganizations];
  }, [allOrganizationsOption]);

  const fromYearOptions = useMemo(() => getFromYearOptions(), []);
  const fromMonthOptions = useMemo(() => (selectedFromYear
    ? getFromMonthOptions(selectedFromYear.value, currentLanguage)
    : []
  ), [currentLanguage, selectedFromYear]);
  const toYearOptions = useMemo(() => (selectedFromYear
    ? getToYearOptions(selectedFromYear.value)
    : []), [selectedFromYear]);
  const toMonthOptions = useMemo(() => ((selectedFromYear && selectedFromMonth && selectedToYear)
    ? getToMonthOptions(
      selectedFromYear.value,
      selectedFromMonth.value,
      selectedToYear.value,
      currentLanguage,
    )
    : []
  ), [selectedFromYear, selectedFromMonth, selectedToYear, currentLanguage]);

  const handleAllTimeChange = useCallback(() => {
    setValue('fromYear', null);
    setValue('fromMonth', null);
    setValue('toYear', null);
    setValue('toMonth', null);
  }, [setValue]);

  const handleFromYearChange = useCallback(() => {
    setValue('fromMonth', null);
    setValue('toYear', null);
    setValue('toMonth', null);
    setValue('allTime', false);
  }, [setValue]);

  const handleFromMonthChange = useCallback(() => {
    setValue('toYear', null);
    setValue('toMonth', null);
  }, [setValue]);

  const handleToYearChange = useCallback(() => {
    setValue('toMonth', null);
  }, [setValue]);

  const handleOrganizationsChange = useCallback((
    event: SyntheticEvent,
    value: IOrganizationOption[],
  ) => {
    const actualOrganizations: IOrganizationOption[] = [];
    let isAllOrganizationsSelected = false;
    value.forEach((organization) => {
      if (organization.value === ALL_ORGANIZATIONS_VALUE) {
        isAllOrganizationsSelected = true;
        return;
      }
      actualOrganizations.push(organization);
    });
    if (isAllOrganizationsSelected && !selectedAllOrganizations) {
      setValue('organizations', [allOrganizationsOption]);
      setValue('allOrganizations', true);
      return;
    }
    setValue('organizations', actualOrganizations);
    setValue('allOrganizations', false);
  }, [selectedAllOrganizations, setValue, allOrganizationsOption]);

  const handleFormSubmit = useCallback((formValue: IDownloadCSVModalFormValue): void => {
    const {
      fromYear,
      fromMonth,
      toYear,
      toMonth,
      allTime,
      organizations,
      allOrganizations,
    } = formValue;

    let organizationIds;
    if (!allOrganizations) {
      organizationIds = organizationId ? [organizationId] : organizations.map(
        (organization) => organization.value,
      );
    }

    onSubmit({
      exportPeriod: allTime ? undefined : {
        fromYear: fromYear!.value,
        fromMonth: fromMonth!.value,
        toYear: toYear!.value,
        toMonth: toMonth!.value,
      },
      allTime: allTime || undefined,
      organizationIds,
      allOrganizations: allOrganizations || undefined,
    });
  }, [onSubmit, organizationId]);

  const disableOnMultipleOrganizations = (
    !selectedAllTime || !watchOrganizations.length
  ) && (
    !selectedFromYear
    || !selectedFromMonth
    || !selectedToYear
    || !selectedToMonth
    || !watchOrganizations.length
  );
  const disableOnSingleOrganization = !selectedAllTime && (
    !selectedFromYear
    || !selectedFromMonth
    || !selectedToYear
    || !selectedToMonth
  );

  const isSubmitButtonDisabled = organizationId
    ? disableOnSingleOrganization
    : disableOnMultipleOrganizations;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isLoading}
      scroll="body"
    >
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        autoComplete="off"
        noValidate
        style={FORM_STYLE}
      >
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('downloadCSVModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container>
              <Grid item xs={3}>
                <Typography>
                  {t('downloadCSVModal.selectPeriod')}
                </Typography>
              </Grid>
              <Grid item xs={9} container flexDirection="column" rowGap={2}>
                <Grid container columnGap={2} flexWrap="nowrap">
                  <Grid container flexDirection="column" rowGap={2}>
                    <Grid item>
                      <Typography>
                        {t('downloadCSVModal.from')}
                      </Typography>
                      <ReactHookFormAutocomplete
                        fullWidth
                        name="fromYear"
                        placeholder={t('downloadCSVModal.fromYear.placeholder')}
                        control={control}
                        options={fromYearOptions}
                        onChange={handleFromYearChange}
                      />
                    </Grid>
                    <ReactHookFormAutocomplete
                      fullWidth
                      name="fromMonth"
                      placeholder={t('downloadCSVModal.fromMonth.placeholder')}
                      control={control}
                      options={fromMonthOptions}
                      onChange={handleFromMonthChange}
                      disabled={!selectedFromYear}
                    />
                  </Grid>
                  <Divider orientation="vertical" />
                  <Grid container flexDirection="column" rowGap={2}>
                    <Grid item>
                      <Typography>
                        {t('downloadCSVModal.to')}
                      </Typography>
                      <ReactHookFormAutocomplete
                        fullWidth
                        name="toYear"
                        placeholder={t('downloadCSVModal.toYear.placeholder')}
                        control={control}
                        options={toYearOptions}
                        onChange={handleToYearChange}
                        disabled={!selectedFromMonth}
                      />
                    </Grid>
                    <ReactHookFormAutocomplete
                      fullWidth
                      name="toMonth"
                      placeholder={t('downloadCSVModal.toMonth.placeholder')}
                      control={control}
                      options={toMonthOptions}
                      disabled={!selectedToYear}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="start" alignItems="center" columnGap={1}>
                  <Grid item>
                    <ReactHookFormCheckbox
                      name="allTime"
                      control={control}
                      onChange={handleAllTimeChange}
                    />
                  </Grid>
                  <Typography>
                    {t('downloadCSVModal.allTime')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {!organizationId && (
              <Grid item container alignItems="center">
                <Grid item xs={3}>
                  <Typography>
                    {t('downloadCSVModal.organization.label')}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <ReactHookFormAutocomplete
                    name="organizations"
                    control={control}
                    useCustomPopperPreventOverflowModifier
                    onChange={handleOrganizationsChange}
                    t={t}
                    multiple
                    placeholder={t('downloadCSVModal.organization.placeholder')}
                    dataSourceRequest={handleGetOrganizationsForLookupRequest}
                    dataSourceResponseParser={handleGetOrganizationsForLookupResponseParser}
                    fetchOnEmptyQuery
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2} justifyContent="center">
            <Grid item xs={6}>
              <Button
                fullWidth
                isLoading={isLoading}
                disabled={isSubmitButtonDisabled}
                type="submit"
              >
                {t('common.download')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(DownloadCSVModal);
