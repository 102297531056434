import { useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useHeroSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { useGetPartnerById } from '../../../../hooks/reactQuery';

interface IPartnerPageBreadcrumbsProps {
  partnerId: string;
}

const PartnerPageBreadcrumbs = (props: IPartnerPageBreadcrumbsProps) => {
  const { partnerId } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useHeroSnackbar();

  const { data: partner, isLoading } = useGetPartnerById(
    { partnerId },
    {
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
        navigate(PageRouteEnum.Partners);
      },
    },
  );

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !partner) {
      return [];
    }
    return [
      {
        title: t('navigation.partners'),
        onClick: () => navigate(PageRouteEnum.Partners),
      },
      { title: partner.name },
    ];
  }, [isLoading, partner, navigate, t]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(PartnerPageBreadcrumbs);
