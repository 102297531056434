import { useMemo, memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useHeroSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import {
  useGetOrganizationById,
  useGetOrganizationEmployeeAccountById,
} from '../../../../hooks/reactQuery';

interface IOrganizationEmployeePageBreadcrumbsProps {
  organizationId: string;
  employeeAccountId: string;
}

const OrganizationEmployeePageBreadcrumbs = (props: IOrganizationEmployeePageBreadcrumbsProps) => {
  const { organizationId, employeeAccountId } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useHeroSnackbar();

  const {
    data: organization,
    isLoading: isOrganizationLoading,
    isError: isOrganizationError,
  } = useGetOrganizationById(
    { organizationId },
  );

  const {
    data: employee,
    isLoading: isEmployeeLoading,
    isError: isEmployeeError,
  } = useGetOrganizationEmployeeAccountById(
    { employeeAccountId },
  );

  const isLoading = isOrganizationLoading || isEmployeeLoading;

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !organization || !employee) {
      return [];
    }
    return [
      {
        title: t('navigation.organizations'),
        onClick: () => navigate(PageRouteEnum.Organizations),
      },
      {
        title: organization.name,
        onClick: () => navigate(`${PageRouteEnum.Organizations}/${organization.organizationId}`),
      },
      { title: employee.name },
    ];
  }, [isLoading, employee, organization, navigate, t]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isOrganizationError) {
      showError({ text: t('common.somethingWentWrong') });
      navigate(PageRouteEnum.Organizations);
      return;
    }
    if (isEmployeeError) {
      showError({ text: t('common.somethingWentWrong') });
      navigate(`${PageRouteEnum.Organizations}/${organizationId}`);
    }
  }, [isLoading, isOrganizationError, isEmployeeError, organizationId, navigate, showError, t]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(OrganizationEmployeePageBreadcrumbs);
