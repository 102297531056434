import {
  ScrollablePanelContent,
  Grid,
  Spinner,
  useTranslations,
  useHeroSnackbar,
  RenderIf,
} from '@uniqkey-frontend/shared-app';
import { memo } from 'react';
import DeletePartnerWidget from './components/DeletePartnerWidget';
import MoveDetailsInfoWidget from './components/MoveDetailsInfoWidget';
import { useGetPartnerMoveInfo } from '../../../../hooks/reactQuery';

interface IPartnerSettingsTabProps {
  partnerId: string;
}

const PartnerSettingsTab = (props: IPartnerSettingsTabProps) => {
  const { partnerId } = props;
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();

  const { data: moveInfo, isLoading: isMoveInfoLoading } = useGetPartnerMoveInfo({ partnerId }, {
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  if (!moveInfo) {
    return null;
  }

  const {
    movedFromPartnerName, movedFromContactName, movedFromContactEmail, movedFromContactPhone,
  } = moveInfo;

  const showMoveDetailsInfoWidget = movedFromPartnerName
    || movedFromContactName
    || movedFromContactEmail
    || movedFromContactPhone;

  if (isMoveInfoLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center" flex={1}>
        <Spinner size="40px" />
      </Grid>
    );
  }

  return (
    <ScrollablePanelContent p={3} pt={1}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DeletePartnerWidget partnerId={partnerId} />
        </Grid>
        <RenderIf condition={!!showMoveDetailsInfoWidget}>
          <Grid item xs={6}>
            <MoveDetailsInfoWidget
              movedFromPartnerName={movedFromPartnerName}
              movedFromContactName={movedFromContactName}
              movedFromContactEmail={movedFromContactEmail}
              movedFromContactPhone={movedFromContactPhone}
            />
          </Grid>
        </RenderIf>
      </Grid>
    </ScrollablePanelContent>
  );
};

export default memo(PartnerSettingsTab);
