import { memo, useCallback, useState } from 'react';
import {
  ListItem,
  Grid,
  G600,
  IconButton,
  Divider,
  CloseIcon,
  E600,
  PromptModal,
  useTranslations,
  useHeroSnackbar,
  TypographyWithTooltip,
  RenderIf,
} from '@uniqkey-frontend/shared-app';
import { GroupUsersResponseModel } from '@uniqkey-backend-partner/api-client';
import { logException } from '../../../../../../../../services/sentryService';
import { useRemoveUsersFromGroup } from '../../../../../../../../hooks/reactQuery';
import ACLEnum from '../../../../../../../../enums/ACLEnum';
import { useUser } from '../../../../../../../../contexts/UserContext';

interface IAddUserToGroupWidgetListItemProps {
  user: GroupUsersResponseModel;
  groupId: string;
}

const ICON_BUTTON_SX = { padding: 0 };

const AddUserToGroupWidgetListItem = (props: IAddUserToGroupWidgetListItemProps) => {
  const { user, groupId } = props;
  const { name, partnerUserId } = user;

  const { t } = useTranslations();
  const { showError, showSuccess } = useHeroSnackbar();
  const { userCan } = useUser();
  const {
    mutate: mutateRemoveUserFromGroup,
    isLoading: isRemoveUserFromGroupLoading,
  } = useRemoveUsersFromGroup();

  const [isRemoveUserFromGroupModalOpen, setIsRemoveUserFromGroupModalOpen] = useState(false);
  const handleRemoveUserFromGroupModalOpen = useCallback(
    () => setIsRemoveUserFromGroupModalOpen(true),
    [],
  );
  const handleRemoveUserFromGroupModalClose = useCallback(
    () => setIsRemoveUserFromGroupModalOpen(false),
    [],
  );

  const handleRemoveUserFromGroup = useCallback(async () => {
    mutateRemoveUserFromGroup(
      { groupId, partnerUserIds: [partnerUserId] },
      {
        onSuccess: () => {
          showSuccess({ text: t('removeUserFromGroupModal.successMessage') });
          handleRemoveUserFromGroupModalClose();
        },
        onError: (e) => {
          showError({ text: t('removeUserFromGroupModal.errorMessage') });
          logException(e, { message: 'AddUserToGroupWidget/handleRemoveUserFromGroup exception' });
        },
      },
    );
  }, [
    mutateRemoveUserFromGroup,
    handleRemoveUserFromGroupModalClose,
    groupId,
    partnerUserId,
    showError,
    showSuccess,
    t,
  ]);

  return (
    <>
      <ListItem>
        <Grid item container>
          <Grid item container justifyContent="space-between" wrap="nowrap" mt={1}>
            <TypographyWithTooltip variant="body2" color={G600} breakWord>
              {name}
            </TypographyWithTooltip>
            <RenderIf condition={userCan(ACLEnum.GroupRemoveUsers)}>
              <IconButton
                disabled={isRemoveUserFromGroupLoading}
                onClick={handleRemoveUserFromGroupModalOpen}
                sx={ICON_BUTTON_SX}
              >
                <CloseIcon color={E600} width={16} height={16} />
              </IconButton>
            </RenderIf>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Divider />
          </Grid>
        </Grid>
      </ListItem>
      {isRemoveUserFromGroupModalOpen && (
      <PromptModal
        open={isRemoveUserFromGroupModalOpen}
        onClose={handleRemoveUserFromGroupModalClose}
        onSubmit={handleRemoveUserFromGroup}
        title={t('removeUserFromGroupModal.title')}
        description={t('removeUserFromGroupModal.description', { name })}
        approvalButtonText="common.remove"
        isLoading={isRemoveUserFromGroupLoading}
      />
      )}
    </>
  );
};

export default memo(AddUserToGroupWidgetListItem);
