import { useCallback, memo, useMemo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  useHeroSnackbar,
  usePubSub,
  ReactHookFormDateRangePickerWithTextField,
  formatDateRanges,
  initDateRange,
  DateRange,
  IISODateRange,
  formatSupporterEmail,
  buildFilterOptionsAsObject,
  IAutocompleteProps,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { GetForLookupResponseModel } from '@uniqkey-backend-partner/api-client';
import PubSubEventEnum from '../../../../../../enums/PubSubEventEnum';
import useEmployeeAccountsAPI from '../../../../../../hooks/useEmployeeAccountsAPI';
import {
  IGetOrganizationAuditLogsParams,
} from '../../../../../../hooks/usePartnerOrganizationsAPI/interfaces';

export interface IOrganizationAuditLogsListFilterSubmitResult {
  employeeAccount: IEmployeeAccountOption | undefined;
  dateRange: IISODateRange | undefined;
  showOnlySupporters: IGetOrganizationAuditLogsParams['showOnlySupporters'];
}

interface IOrganizationAuditLogsListFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IOrganizationAuditLogsListFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IOrganizationAuditLogsListFilterSubmitResult
  organizationId: string;
}

interface IEmployeeAccountOption {
  value: GetForLookupResponseModel['employeeAccountId'];
  label: GetForLookupResponseModel['email'];
}

type TShowOnlySupportersOption = {
  label: string;
  value: IOrganizationAuditLogsListFilterSubmitResult['showOnlySupporters'];
} | null;

interface IFormValues {
  employeeAccount: IEmployeeAccountOption | null;
  dateRange: DateRange;
  showOnlySupporters: TShowOnlySupportersOption;
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const OrganizationAuditLogsListFilter = (props: IOrganizationAuditLogsListFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues, organizationId,
  } = props;
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getEmployeeAccountsForLookup } = useEmployeeAccountsAPI();

  const [showOnlySupportersOptions, showOnlySupportersOptionsAsObject] = useMemo(() => {
    const options = [
      { label: t('organizationAuditLogsFilter.showOnlySupporters.values.enabled'), value: true },
      { label: t('organizationAuditLogsFilter.showOnlySupporters.values.disabled'), value: false },
    ];
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      employeeAccount: initialValues.employeeAccount ?? null,
      dateRange: initDateRange(initialValues.dateRange),
      showOnlySupporters: showOnlySupportersOptionsAsObject[
        initialValues.showOnlySupporters?.toString() as
          keyof typeof showOnlySupportersOptionsAsObject
      ] ?? null,
    },
  });

  const handleGetEmployeeAccountsRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getEmployeeAccountsForLookup({
        page: 1, pageLength: 100, organizationId, searchQuery, showSupporters: true,
      });
      return data;
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      return [];
    }
  }, [getEmployeeAccountsForLookup, organizationId, showError, t]);

  const handleGetEmployeeAccountsResponseParser = useCallback(
    (employeeAccounts: GetForLookupResponseModel[]) => employeeAccounts.map(
      (employeeAccount) => ({
        value: employeeAccount.employeeAccountId,
        label: formatSupporterEmail(employeeAccount.email, 'supporter.emailSuffix'),
      }),
    ),
    [],
  );

  const getOptionKey = useCallback<
    NonNullable<IAutocompleteProps['getOptionKey']>
  >((option) => option.value.toString(), []);

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const { employeeAccount, dateRange, showOnlySupporters } = value;
    const processedDateRange = dateRange.from && dateRange.to
      ? formatDateRanges(dateRange)
      : undefined;
    onSubmit({
      employeeAccount: employeeAccount ?? undefined,
      dateRange: processedDateRange,
      showOnlySupporters: showOnlySupporters?.value,
    });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const clearAll = useCallback(() => {
    setValue('employeeAccount', null, { shouldDirty: true });
    setValue('dateRange', initDateRange(), { shouldDirty: true });
    setValue('showOnlySupporters', null, { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      employeeAccount: null,
      dateRange: initDateRange(),
      showOnlySupporters: null,
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationAuditLogsFilter.date.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormDateRangePickerWithTextField
                  name="dateRange"
                  control={control}
                  t={t}
                  placement="bottom-start"
                  fullWidth
                  selectLabelText={t('organizationAuditLogsFilter.date.placeholder')}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationAuditLogsFilter.employeeAccount.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  t={t}
                  name="employeeAccount"
                  placeholder={t('organizationAuditLogsFilter.employeeAccount.placeholder')}
                  dataSourceRequest={handleGetEmployeeAccountsRequest}
                  dataSourceResponseParser={handleGetEmployeeAccountsResponseParser}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationAuditLogsFilter.showOnlySupporters.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="showOnlySupporters"
                  options={showOnlySupportersOptions}
                  getOptionKey={getOptionKey}
                  placeholder={t('organizationAuditLogsFilter.showOnlySupporters.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(OrganizationAuditLogsListFilter);
