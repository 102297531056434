import { useCallback, memo, useState } from 'react';
import {
  useHeroSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { GetPartnerUsersResponsePartnerUser } from '@uniqkey-backend-partner/api-client';
import { useQueryClient } from 'react-query';
import { logException } from '../../../../services/sentryService';
import useGroupsAPI from '../../../../hooks/useGroupsAPI';
import { resetGroupUsersRelatedQueries } from '../../../../hooks/reactQuery';
import EntitySelectorModal, {
  TEntitySelectorModalOnSubmit,
} from '../../../../components/EntitySelectorModal';

interface IGroupUsersSelectorModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupId: string;
}

interface IParsedEntity {
  id: GetPartnerUsersResponsePartnerUser['partnerUserId'];
  label: string;
}

const GroupUsersSelectorModal = (props: IGroupUsersSelectorModalProps) => {
  const {
    isOpen, onClose, groupId,
  } = props;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showSuccess, showError } = useHeroSnackbar();
  const { addUsersToGroup, getGroupUsersForLookup } = useGroupsAPI();

  const [isGroupUsersSelectorModalLoading, setIsGroupUsersSelectorModalLoading] = useState(false);

  const handleAutocompleteRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getGroupUsersForLookup({
        page: 1, pageLength: 100, groupId, searchQuery,
      });
      return data;
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupUsersSelectorModal/handleAutocompleteRequest exception',
      });
      return [];
    }
  }, [getGroupUsersForLookup, groupId, showError, t]);

  const handleSubmitEntitySelectorModal = useCallback<
    TEntitySelectorModalOnSubmit<IParsedEntity[]>
  >(async (value) => {
    try {
      const partnerUserIds = value.map((el: { id: string }) => el.id);
      setIsGroupUsersSelectorModalLoading(true);
      await addUsersToGroup({ partnerUserIds, groupId });
      showSuccess({
        text: t('addUsersToGroupModal.userAddedToGroup', { count: partnerUserIds.length }),
      });
      onClose();
      resetGroupUsersRelatedQueries(queryClient, groupId);
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupUsersSelectorModal/handleSubmitEntitySelectorModal exception',
      });
    } finally {
      setIsGroupUsersSelectorModalLoading(false);
    }
  }, [
    addUsersToGroup,
    groupId,
    onClose,
    queryClient,
    showError,
    showSuccess,
    t,
  ]);

  const handleResponseEntitySelectorModal = useCallback(
    (users: GetPartnerUsersResponsePartnerUser[]) => users.map(
      (user: GetPartnerUsersResponsePartnerUser) => ({
        id: user.partnerUserId,
        label: `${user.name}, ${user.email}`,
      }),
    ),
    [],
  );

  return (
    <EntitySelectorModal
      isOpen={isOpen}
      title={t('addUsersToGroupModal.title')}
      placeholder={t('addUsersToGroupModal.selectUsersPlaceholder')}
      description={t('addUsersToGroupModal.description')}
      onSubmit={handleSubmitEntitySelectorModal}
      request={handleAutocompleteRequest}
      responseParser={handleResponseEntitySelectorModal}
      onClose={onClose}
      isLoading={isGroupUsersSelectorModalLoading}
    />
  );
};

export default memo(GroupUsersSelectorModal);
