import {
  Grid, Typography, G600, TypographyWithTooltip, G500,
} from '@uniqkey-frontend/shared-app';
import { memo } from 'react';

interface IMoveDetailsInfoWidgetItemProps {
  title: string;
  value?: string | null;
}

const MoveDetailsInfoWidgetItem = (props: IMoveDetailsInfoWidgetItemProps) => {
  const { title, value } = props;
  return (
    <Grid container flexDirection="column">
      <Typography variant="caption" color={G600}>
        {title}
      </Typography>
      <TypographyWithTooltip color={G500} breakWord>
        {value}
      </TypographyWithTooltip>
    </Grid>
  );
};

export default memo(MoveDetailsInfoWidgetItem);
