import { useMemo, memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useHeroSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import {
  useGetOrganizationById,
  useGetOrganizationEmployeeAccountById,
  useGetPartnerById,
} from '../../../../hooks/reactQuery';

interface IPartnerOrganizationEmployeePageBreadcrumbsProps {
  partnerId: string;
  organizationId: string;
  employeeAccountId: string;
}

const PartnerOrganizationEmployeePageBreadcrumbs = (
  props: IPartnerOrganizationEmployeePageBreadcrumbsProps,
) => {
  const { partnerId, organizationId, employeeAccountId } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useHeroSnackbar();

  const {
    data: partner,
    isLoading: isPartnerLoading,
    isError: isPartnerError,
  } = useGetPartnerById(
    { partnerId },
  );

  const {
    data: organization,
    isLoading: isOrganizationLoading,
    isError: isOrganizationError,
  } = useGetOrganizationById(
    { organizationId },
  );

  const {
    data: employee,
    isLoading: isEmployeeLoading,
    isError: isEmployeeError,
  } = useGetOrganizationEmployeeAccountById(
    { employeeAccountId },
  );

  const isLoading = isPartnerLoading || isOrganizationLoading || isEmployeeLoading;

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !partner || !organization || !employee) {
      return [];
    }
    return [
      {
        title: t('navigation.partners'),
        onClick: () => navigate(PageRouteEnum.Partners),
      },
      {
        title: t(partner.name),
        onClick: () => navigate(`${PageRouteEnum.Partners}/${partnerId}`),
      },
      {
        title: t(organization.name),
        onClick: () => navigate(`${PageRouteEnum.Partners}/${partnerId}/${organizationId}`),
      },
      { title: employee.name },
    ];
  }, [isLoading, employee, organization, partner, navigate, organizationId, partnerId, t]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isPartnerError) {
      showError({ text: t('common.somethingWentWrong') });
      navigate(PageRouteEnum.Partners);
      return;
    }
    if (isOrganizationError) {
      showError({ text: t('common.somethingWentWrong') });
      navigate(`${PageRouteEnum.Partners}/${partnerId}`);
      return;
    }
    if (isEmployeeError) {
      showError({ text: t('common.somethingWentWrong') });
      navigate(`${PageRouteEnum.Partners}/${partnerId}/${organizationId}`);
    }
  }, [
    isLoading,
    isPartnerError,
    isOrganizationError,
    isEmployeeError,
    organizationId,
    navigate,
    partnerId,
    showError,
    t,
  ]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(PartnerOrganizationEmployeePageBreadcrumbs);
