import { useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useHeroSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { useGetOrganizationById } from '../../../../hooks/reactQuery';

interface IOrganizationPageBreadcrumbsProps {
  organizationId: string;
}

const OrganizationPageBreadcrumbs = (props: IOrganizationPageBreadcrumbsProps) => {
  const { organizationId } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useHeroSnackbar();

  const { data: organization, isLoading } = useGetOrganizationById(
    { organizationId },
    {
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
        navigate(PageRouteEnum.Organizations);
      },
    },
  );

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !organization) {
      return [];
    }
    return [
      {
        title: t('navigation.organizations'),
        onClick: () => navigate(PageRouteEnum.Organizations),
      },
      { title: organization.name },
    ];
  }, [isLoading, organization, navigate, t]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(OrganizationPageBreadcrumbs);
