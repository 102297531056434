import {
  memo, useCallback, useState, useMemo,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  NumericTextField,
  DialogActions,
  Button,
  useTranslations,
  Typography,
  G600,
  ReactHookFormCheckbox,
  useHeroSnackbar,
  RenderIf,
  Spinner,
  E600,
  minLengthValidator,
  maxLengthValidator,
  INumericTextFieldProps,
  getUseTimerExpiryTimestamp,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { useTimer } from 'react-timer-hook';
import usePartnersAPI from '../../../../../../hooks/usePartnersAPI';
import { logException } from '../../../../../../services/sentryService';
import { UNIQKEY_FINDER_IGNORE_CLASSNAME_PROP } from '../../../../../../constants';

export interface IDeletePartnerModalSubmitResult {
  code: string;
}

interface IDeletePartnerModalFormValue {
  code: string;
  agreement: boolean;
}

interface IDeletePartnerModalProps {
  partnerName: string;
  partnerId: string;
  isOpen: boolean;
  isDeletePartnerLoading: boolean;
  onSubmit: (params: IDeletePartnerModalSubmitResult) => Promise<void> | void;
  onClose: () => void;
}

const DESCRIPTION_TRANSLATIONS_KEYS = [
  {
    first: 'deletePartnerModal.description.firstItem.firstPart',
    second: 'deletePartnerModal.description.firstItem.secondPart',
  },
  {
    first: 'deletePartnerModal.description.secondItem.firstPart',
    second: 'deletePartnerModal.description.secondItem.secondPart',
  },
  {
    first: 'deletePartnerModal.description.thirdItem.firstPart',
    second: 'deletePartnerModal.description.thirdItem.secondPart',
  },
  {
    first: 'deletePartnerModal.description.fourthItem.firstPart',
    second: 'deletePartnerModal.description.fourthItem.secondPart',
  },
  {
    first: 'deletePartnerModal.description.fifthItem.firstPart',
    second: 'deletePartnerModal.description.fifthItem.secondPart',
  },
];

const DeletePartnerModal = (props: IDeletePartnerModalProps) => {
  const {
    partnerName, partnerId, isOpen, isDeletePartnerLoading, onSubmit, onClose,
  } = props;

  const { t } = useTranslations();
  const { requestPartnerDeletion } = usePartnersAPI();
  const { showError } = useHeroSnackbar();
  const {
    totalSeconds,
    restart,
  } = useTimer({
    expiryTimestamp: getUseTimerExpiryTimestamp(60),
  });

  const [isResendCodeLoading, setIsResendCodeLoading] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    register,
    setValue,
  } = useForm<IDeletePartnerModalFormValue>({
    defaultValues: {
      code: '',
      agreement: false,
    },
    mode: 'all',
  });

  const resendCode = useCallback(async () => {
    try {
      setIsResendCodeLoading(true);
      await requestPartnerDeletion({ partnerId });
      restart(getUseTimerExpiryTimestamp(60));
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, { message: 'DeletePartnerModal/resendCode exception' });
    } finally {
      setIsResendCodeLoading(false);
    }
  }, [partnerId, requestPartnerDeletion, restart, showError, t]);

  const handleFormSubmit = useCallback((formValue: IDeletePartnerModalFormValue) => {
    const { code } = formValue;
    onSubmit({ code });
  }, [onSubmit]);

  const descriptionElements = useMemo(
    () => DESCRIPTION_TRANSLATIONS_KEYS.map((descriptionTranslationKeys) => (
      <Grid container flexWrap="nowrap" key={descriptionTranslationKeys.first}>
        <Grid item mx={1}>
          <Typography>
            •
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {t(descriptionTranslationKeys.first)}
            &nbsp;
            <Typography component="span" color={E600}>
              {partnerName}
            </Typography>
            &nbsp;
            <Typography component="span">
              {t(descriptionTranslationKeys.second)}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    )),
    [partnerName, t],
  );

  const handleCodeChange = useCallback<
    NonNullable<INumericTextFieldProps['onChange']>
  >((e) => {
    setValue('code', e.target.value);
  }, [setValue]);

  const [enteredCode, selectedAgreement] = watch(['code', 'agreement']);

  const isResetCodeDisabled = !!totalSeconds || isResendCodeLoading || isDeletePartnerLoading;

  const isDeletePartnerDisabled = !enteredCode || !selectedAgreement || isDeletePartnerLoading;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isDeletePartnerLoading}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off" noValidate>
        <DialogTitle isLoading={isDeletePartnerLoading} onClose={onClose}>
          {t('deletePartnerModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={2}>
            <Grid container item xs={12} flexDirection="column" flexWrap="nowrap">
              <Typography variant="subtitle2">
                {t('deletePartnerModal.description.title.firstPart')}
                &nbsp;
                <Typography component="span" variant="subtitle2" color={E600}>
                  {partnerName}
                </Typography>
                &nbsp;
                <Typography component="span" variant="subtitle2">
                  {t('deletePartnerModal.description.title.secondPart')}
                </Typography>
              </Typography>
              <Typography variant="caption">
                {t('deletePartnerModal.description.subtitle')}
              </Typography>
              {descriptionElements}
            </Grid>
            <Grid item container xs={12} my={1} columnGap={1} alignItems="center" flexWrap="nowrap">
              <Grid item>
                <ReactHookFormCheckbox
                  name="agreement"
                  label={`*${t('deletePartnerModal.agreement')}`}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <NumericTextField
                allowLeadingZeros
                fullWidth
                autoComplete="none"
                value={enteredCode}
                onChange={handleCodeChange}
                label={`*${t('deletePartnerModal.code.label')}`}
                error={!!errors.code}
                helperText={errors.code?.message}
                inputProps={{
                  ...register('code', {
                    validate: {
                      minLength: (
                        code,
                      ) => minLengthValidator(6, code as string) || t('validation.invalidFormat'),
                      maxLength: (
                        code,
                      ) => maxLengthValidator(6, code as string) || t('validation.invalidFormat'),
                    },
                  }),
                  ...UNIQKEY_FINDER_IGNORE_CLASSNAME_PROP,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" fontWeight={400} color={G600}>
                {t('deletePartnerModal.codeAlreadySent')}
                &nbsp;
                <Typography
                  component="span"
                  variant="body2"
                  fontWeight={400}
                  asLink
                  disabled={isResetCodeDisabled}
                  onClick={isResetCodeDisabled ? undefined : resendCode}
                >
                  {t('deletePartnerModal.resend')}
                </Typography>
                &nbsp;
                <RenderIf condition={isResendCodeLoading}>
                  <Spinner size={12} />
                </RenderIf>
                <RenderIf condition={!!totalSeconds}>
                  <Typography component="span" variant="body2" fontWeight={400}>
                    {totalSeconds}
                  </Typography>
                </RenderIf>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                disabled={isDeletePartnerLoading}
                onClick={onClose}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="error"
                isLoading={isDeletePartnerLoading}
                disabled={isDeletePartnerDisabled}
                type="submit"
              >
                {t('common.delete')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(DeletePartnerModal);
