import {
  useCallback,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useHeroSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { useSearchParams } from 'react-router-dom';
import { GroupUserOrderProperty } from '@uniqkey-backend-partner/api-client';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import { IGetGroupUsersParams, TGetGroupUsersMethod } from '../../useGroupsAPI/interfaces';
import { IGroupUsersTableRow } from '../../../components/tables/GroupUsersTable';
import useGroupsAPI from '../../useGroupsAPI';

export const REACT_QUERY_GROUP_USERS_KEY = [ReactQueryKeyEnum.GroupUsers];

interface IPersistentFilters extends Pick<IGetGroupUsersParams, 'groupId'> {}

export interface IUseGroupUsersTableParams {
  persistentFilters?: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseGroupUsersTableReturn extends
  Omit<IUseTableReturn<IGroupUsersTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseQueryTableFetchReturn<TGetGroupUsersMethod>, 'data' | 'isFetchedAfterMount'>
{
  groupUsers: IUseQueryTableFetchReturn<TGetGroupUsersMethod>['data'];
  selectedGroupUsers: IUseTableReturn<IGroupUsersTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetGroupUsersParams,
    'page' | 'pageLength' | 'groupId' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<IGroupUsersTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetGroupUsersParams['page'];
  perPage: IGetGroupUsersParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: GroupUserOrderProperty },
  ),
  columnOrderDirection: true,
};

const useGroupUsersTable = (
  params: IUseGroupUsersTableParams,
): IUseGroupUsersTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getGroupUsers } = useGroupsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedGroupUsers,
    resetTableMethods,
    ...restTableProps
  } = useTable<IGroupUsersTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: groupUsers, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_GROUP_USERS_KEY,
    queryClient,
    request: getGroupUsers,
    params: {
      page: activePage,
      pageLength: perPage,
      orderPropertyName: GroupUserOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof GroupUserOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    groupUsers,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedGroupUsers,
    localization,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useGroupUsersTable;
