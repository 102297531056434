import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useHeroSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useFilterButton,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IUseFilterButtonReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  OrganizationOrderProperty,
  GetOrganizationsResponseModel,
} from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetOrganizationsParams,
  TGetOrganizationsMethod,
} from '../../usePartnerOrganizationsAPI/interfaces';
import usePartnerOrganizationsAPI from '../../usePartnerOrganizationsAPI';
import {
  IOrganizationsListFilterSubmitResult,
} from '../../../pages/OrganizationsPage/components/OrganizationsListFilter';
import { isCountryValid } from '../../../helpers/countries';

export interface IOrganizationsTableRow extends GetOrganizationsResponseModel {}

export const REACT_QUERY_ORGANIZATIONS_KEY = [ReactQueryKeyEnum.Organizations];

interface IPersistentFilters extends Pick<IGetOrganizationsParams, 'partnerId'> {}

export interface IUseOrganizationsTableParams {
  persistentFilters?: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseOrganizationsTableReturn extends
  Omit<IUseTableReturn<IOrganizationsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseQueryTableFetchReturn<TGetOrganizationsMethod>, 'data' | 'isFetchedAfterMount'>,
  IUseFilterButtonReturn
{
  organizations: IUseQueryTableFetchReturn<TGetOrganizationsMethod>['data'];
  selectedOrganizations: IUseTableReturn<IOrganizationsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetOrganizationsParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetOrganizationsParams['searchQuery']>>;
  filterValues: IOrganizationsListFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IOrganizationsListFilterSubmitResult>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetOrganizationsParams,
    'page' | 'pageLength' | 'partnerId' | 'orderPropertyName' | 'movedFromPartnerId' |
    'isDescending'
  >,
  Pick<IUseTableReturn<IOrganizationsTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetOrganizationsParams['page'];
  perPage: IGetOrganizationsParams['pageLength'];
  movedFromPartner: IOrganizationsListFilterSubmitResult['movedFromPartner'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: OrganizationOrderProperty },
  ),
  columnOrderDirection: true,
  countryFilter: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.STRING,
    { isValid: isCountryValid },
  ),
  movedFromPartner: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.OBJECT,
  ),
  allMovedPartners: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.BOOLEAN,
  ),
};

const useOrganizationsTable = (
  params: IUseOrganizationsTableParams,
): IUseOrganizationsTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getOrganizations } = usePartnerOrganizationsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    countryFilter: initialCountryFilter,
    movedFromPartner: initialMovedFromPartner,
    allMovedPartners: initialAllMovedPartners,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetOrganizationsParams['searchQuery']
  >(() => initialSearchQuery);
  const [filterValues, setFilterValues] = useState<
    IOrganizationsListFilterSubmitResult
  >({
    countryFilter: initialCountryFilter,
    movedFromPartner: initialMovedFromPartner,
    allMovedPartners: initialAllMovedPartners,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedOrganizations,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
    setFilterValues({
      countryFilter: undefined,
      movedFromPartner: undefined,
      allMovedPartners: undefined,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: organizations, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_ORGANIZATIONS_KEY,
    queryClient,
    request: getOrganizations,
    params: {
      page: activePage,
      pageLength: perPage,
      countryFilter: filterValues.countryFilter,
      movedFromPartnerId: filterValues.movedFromPartner?.value,
      allMovedPartners: filterValues.allMovedPartners,
      searchQuery,
      orderPropertyName: OrganizationOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof OrganizationOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
    countryFilter: filterValues.countryFilter,
    movedFromPartner: {
      value: filterValues.movedFromPartner,
      parser: JSON.stringify,
    },
    allMovedPartners: filterValues.allMovedPartners,
  });

  return {
    organizations,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedOrganizations,
    localization,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    ...restTableProps,
  };
};

export default useOrganizationsTable;
