import {
  memo, useCallback, useState, useMemo,
} from 'react';
import {
  Grid,
  Typography,
  TypographyWithTooltip,
  G600,
  G900,
  S600,
  useTranslations,
  EntityDetailsContainer,
  Label,
  useHeroSnackbar,
} from '@uniqkey-frontend/shared-app';
import ACLEnum from '../../../../../../enums/ACLEnum';
import {
  useGetPartnerUserById,
  useUpdatePartnerUser,
  useUpdatePartnerUserRole,
} from '../../../../../../hooks/reactQuery';
import EditPartnerUserModal, { IEditPartnerUserModalReturnValue } from '../EditPartnerUserModal';
import { useUser } from '../../../../../../contexts/UserContext';
import { logException } from '../../../../../../services/sentryService';
import { USER_ROLES_TRANSLATION_KEYS } from '../../../../../../constants';
import { isAdmin, isLimitedAdmin } from '../../../../../../helpers/userRole';

interface IPartnerUserDetailsWidgetProps {
  partnerUserId: string;
}

const SUCH_PARTNER_USER_ALREADY_EXISTS_ERROR = 'Such_PartnerUser_already_exists';

const PartnerUserDetailsWidget = (props: IPartnerUserDetailsWidgetProps) => {
  const { partnerUserId } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useHeroSnackbar();
  const { currentUser, userCan } = useUser();

  const {
    data: partnerUser,
    isError,
    isLoading: isPartnerUserLoading,
  } = useGetPartnerUserById(
    { partnerUserId },
  );

  const {
    mutateAsync: mutatePartnerUser,
    isLoading: isUpdatePartnerUserLoading,
  } = useUpdatePartnerUser({
    partnerUserId,
    useOptimisticUpdates: true,
  });
  const {
    mutateAsync: mutatePartnerUserRole,
    isLoading: isUpdatePartnerUserRoleLoading,
  } = useUpdatePartnerUserRole({
    partnerUserId,
    useOptimisticUpdates: true,
  });

  const [isEditPartnerUserModalOpen, setIsEditPartnerUserModalOpen] = useState(false);
  const handleEditPartnerUserModalOpen = useCallback(
    () => setIsEditPartnerUserModalOpen(true),
    [],
  );
  const handleEditPartnerUserModalClose = useCallback(
    () => setIsEditPartnerUserModalOpen(false),
    [],
  );

  const { id, role: currentUserRole } = currentUser ?? {};
  const {
    name: partnerUserName,
    email: partnerUserEmail,
    role: partnerUserRole,
  } = partnerUser ?? {};

  const canEditUserRole = useMemo(() => {
    const isCurrentUserLimitedAdmin = isLimitedAdmin(currentUserRole);
    const isTargetUserAdmin = isAdmin(partnerUserRole);
    const canEditRoles = userCan(ACLEnum.PartnerUserEditRoles);
    /*
      Show role selector unless:
        there is no access right for that;
        the current user is Limited Admin, and the selected user is Admin.
    */
    return canEditRoles && !(isCurrentUserLimitedAdmin && isTargetUserAdmin);
  }, [currentUserRole, partnerUserRole, userCan]);

  const handleUpdatePartnerUser = useCallback(async (value: IEditPartnerUserModalReturnValue) => {
    try {
      const { name, email, role } = value;
      await mutatePartnerUser({ partnerUserId, name, email });
      if (canEditUserRole) {
        await mutatePartnerUserRole({ partnerUserId, role });
      }
      showSuccess({ text: t('editPartnerUserModal.partnerUserEdited') });
      handleEditPartnerUserModalClose();
    } catch (e: any) {
      let key = 'common.somethingWentWrong';
      if (e?.response?.data?.includes(SUCH_PARTNER_USER_ALREADY_EXISTS_ERROR)) {
        key = 'editPartnerUserModal.suchPartnerUserAlreadyExists';
      }
      showError({ text: t(key) });
      logException(e, {
        message: 'PartnerUserDetailsWidget/handleUpdatePartnerUser exception',
      });
    }
  }, [
    mutatePartnerUser,
    mutatePartnerUserRole,
    partnerUserId,
    handleEditPartnerUserModalClose,
    canEditUserRole,
    showError,
    showSuccess,
    t,
  ]);

  if (isError) {
    return null;
  }

  const isCurrentUser = partnerUserId === id;

  const translatedUserRole = t(
    USER_ROLES_TRANSLATION_KEYS[partnerUserRole as keyof typeof USER_ROLES_TRANSLATION_KEYS],
  );

  return (
    <>
      <EntityDetailsContainer
        container
        isLoading={isPartnerUserLoading}
        onClick={handleEditPartnerUserModalOpen}
        editable={userCan(ACLEnum.PartnerUserEdit) && !isCurrentUser}
        justifyContent="space-between"
        rowGap={3}
      >
        <Grid container item flexWrap="nowrap">
          <Grid container item flexDirection="column">
            <Typography variant="caption" color={G600} mb={1}>
              {t('partnerUserDetailsWidget.name')}
            </Typography>
            <TypographyWithTooltip variant="h6" color={G900} breakWord>
              {partnerUserName}
            </TypographyWithTooltip>
          </Grid>
          <Grid item>
            <Label text={translatedUserRole} bgColor={S600} />
          </Grid>
        </Grid>
        <Grid container item flexDirection="column">
          <Typography variant="caption" color={G600} mb={1}>
            {t('partnerUserDetailsWidget.email')}
          </Typography>
          <TypographyWithTooltip color={G600} breakWord>
            {partnerUserEmail}
          </TypographyWithTooltip>
        </Grid>
      </EntityDetailsContainer>
      {isEditPartnerUserModalOpen && (
        <EditPartnerUserModal
          isOpen={isEditPartnerUserModalOpen}
          isLoading={isUpdatePartnerUserLoading || isUpdatePartnerUserRoleLoading}
          onClose={handleEditPartnerUserModalClose}
          onSubmit={handleUpdatePartnerUser}
          partnerUser={partnerUser!}
        />
      )}
    </>
  );
};

export default memo(PartnerUserDetailsWidget);
