import { useCallback, useState } from 'react';
import {
  G900,
  Box,
  Button,
  Grid,
  HeroLogoWithTextIcon,
  minLengthValidator,
  maxLengthValidator,
  Panel,
  QrCode,
  Typography,
  useTranslations,
  validateDigits,
  useHeroSnackbar,
  ReactHookFormTextField,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { logException } from '../../services/sentryService';
import { loginTwoFA, setupTwoFA } from '../../services/authService';
import { useUser } from '../../contexts/UserContext';
import useTwoFactorSecretGenerator from '../../hooks/useTwoFactorSecretGenerator';
import UnauthenticatedStepHelperSection from '../../components/UnauthenticatedStepHelperSection';

interface ISetupTwoFAFormValue {
  setupTwoFA: string;
}

const MAX_WIDTH = 720;
const MIN_HEIGHT = 748;

const SetupTwoFAPage = () => {
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { currentUser } = useUser();
  const { name, email } = currentUser ?? {};

  const [isSetupTwoFALoading, setIsSetupTwoFALoading] = useState(false);

  const { twoFactorSecret } = useTwoFactorSecretGenerator();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<ISetupTwoFAFormValue>({
    defaultValues: {
      setupTwoFA: '',
    },
  });

  const handleOnSubmit = useCallback(async (form: ISetupTwoFAFormValue) => {
    try {
      setIsSetupTwoFALoading(true);
      const { setupTwoFA: otpCode } = form;
      await setupTwoFA({ twoFactorSecret, otpCode });
      await loginTwoFA({ otpCode });
      // do not set 'isLoading' to 'false' here so the submit button remains disabled
    } catch (e) {
      setIsSetupTwoFALoading(false);
      showError({ text: t('setupTwoFAPage.errorMessage') });
      logException(e, {
        message: 'SetupTwoFAPage/handleOnSubmit exception',
      });
    }
  }, [twoFactorSecret, showError, t]);

  return (
    <Grid
      container
      className="min-height-100-vh"
      alignItems="center"
      justifyContent="center"
    >
      <Box my={5}>
        <Panel>
          <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete="off">
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              flexDirection="column"
              rowGap={4}
              p={4}
              maxWidth={MAX_WIDTH}
              minHeight={MIN_HEIGHT}
            >
              <Grid item container alignItems="center" justifyContent="center">
                <HeroLogoWithTextIcon height={60} width={210} />
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Grid item>
                  <Typography variant="h4" color={G900}>
                    {t('setupTwoFAPage.title', { name })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography align="center" color={G900}>
                    {t('setupTwoFAPage.description', { twoFactorSecret })}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <QrCode
                  padding={0}
                  value={`otpauth://totp/${email}?secret=${twoFactorSecret}&issuer=Uniqkey`}
                />
              </Grid>
              <Grid item container xs={9}>
                <ReactHookFormTextField
                  control={control}
                  id="2fa-form-input"
                  name="setupTwoFA"
                  autoFocus
                  fullWidth
                  type="text"
                  error={!!errors.setupTwoFA}
                  label={t('setupTwoFAPage.enterTwoFA.label')}
                  placeholder={t('setupTwoFAPage.enterTwoFA.placeholder')}
                  helperText={errors.setupTwoFA?.message}
                  rules={{
                    validate: {
                      digit: (twoFA) => validateDigits(twoFA) || t('validation.invalidFormat'),
                      minLength: (
                        twoFA,
                      ) => minLengthValidator(6, twoFA) || t('validation.invalidFormat'),
                      maxLength: (
                        twoFA,
                      ) => maxLengthValidator(6, twoFA) || t('validation.invalidFormat'),
                    },
                  }}
                />
              </Grid>
              <Grid item container xs={4}>
                <Button
                  isLoading={isSetupTwoFALoading}
                  type="submit"
                  fullWidth
                  disabled={!isValid}
                >
                  {t('setupTwoFAPage.loginButton')}
                </Button>
              </Grid>
              <UnauthenticatedStepHelperSection />
            </Grid>
          </form>
        </Panel>
      </Box>
    </Grid>
  );
};

export default SetupTwoFAPage;
