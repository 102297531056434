import { useCallback, memo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  useHeroSnackbar,
  usePubSub,
  ReactHookFormDateRangePickerWithTextField,
  formatDateRanges,
  initDateRange,
  DateRange,
  IISODateRange,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { GetPartnerUsersResponsePartnerUser } from '@uniqkey-backend-partner/api-client';
import PubSubEventEnum from '../../../../../../enums/PubSubEventEnum';
import usePartnerUsersAPI from '../../../../../../hooks/usePartnerUsersAPI';

export interface IAuditLogsListFilterSubmitResult {
  partnerUser: IPartnerUserOption | undefined;
  dateRange: IISODateRange | undefined;
}

interface IAuditLogsListFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IAuditLogsListFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IAuditLogsListFilterSubmitResult
}

interface IPartnerUserOption {
  value: GetPartnerUsersResponsePartnerUser['partnerUserId'];
  label: GetPartnerUsersResponsePartnerUser['email'];
}

interface IFormValues {
  partnerUser: IPartnerUserOption | null;
  dateRange: DateRange;
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const AuditLogsListFilter = (props: IAuditLogsListFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues,
  } = props;
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getPartnerUsers } = usePartnerUsersAPI();

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      partnerUser: initialValues.partnerUser ?? null,
      dateRange: initDateRange(initialValues.dateRange),
    },
  });

  const handleGetPartnerUsersRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getPartnerUsers({ page: 1, pageLength: 100, searchQuery });
      return data;
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      return [];
    }
  }, [getPartnerUsers, showError, t]);

  const handleGetPartnerUsersResponseParser = useCallback(
    (partnerUsers: GetPartnerUsersResponsePartnerUser[]) => partnerUsers.map(
      (partnerUser: GetPartnerUsersResponsePartnerUser) => ({
        value: partnerUser.partnerUserId,
        label: partnerUser.email,
      }),
    ),
    [],
  );

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const { partnerUser, dateRange } = value;
    const processedDateRange = dateRange.from && dateRange.to
      ? formatDateRanges(dateRange)
      : undefined;
    onSubmit({
      partnerUser: partnerUser ?? undefined,
      dateRange: processedDateRange,
    });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const clearAll = useCallback(() => {
    setValue('partnerUser', null, { shouldDirty: true });
    setValue('dateRange', initDateRange(), { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      partnerUser: null,
      dateRange: initDateRange(),
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('auditLogsFilter.date.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormDateRangePickerWithTextField
                  name="dateRange"
                  control={control}
                  t={t}
                  placement="bottom-start"
                  fullWidth
                  selectLabelText={t('auditLogsFilter.date.placeholder')}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('auditLogsFilter.user.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  t={t}
                  name="partnerUser"
                  placeholder={t('auditLogsFilter.user.placeholder')}
                  dataSourceRequest={handleGetPartnerUsersRequest}
                  dataSourceResponseParser={handleGetPartnerUsersResponseParser}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(AuditLogsListFilter);
