import { memo, useMemo } from 'react';
import {
  Grid,
  Typography,
  TypographyWithTooltip,
  G600,
  G900,
  S600,
  useTranslations,
  EntityDetailsContainer,
  Label,
  Box,
  formatDate,
  DateTimeFormatEnum,
} from '@uniqkey-frontend/shared-app';
import { GetEmployeeAccountByIdResponse } from '@uniqkey-backend-partner/api-client';
import { useGetOrganizationEmployeeAccountById } from '../../../../../../hooks/reactQuery';
import { EMPLOYEE_ACCOUNT_TYPES_TRANSLATION_KEYS } from '../../../../../../constants';

interface IOrganizationEmployeeDetailsWidgetProps {
  employeeAccountId: string;
}

const OrganizationEmployeeDetailsWidget = (props: IOrganizationEmployeeDetailsWidgetProps) => {
  const { employeeAccountId } = props;
  const { t } = useTranslations();

  const {
    data: employeeAccount,
    isError,
    isLoading,
  } = useGetOrganizationEmployeeAccountById(
    { employeeAccountId },
  );

  const {
    name, email, lastActivity, employeeAccountType,
  } = employeeAccount ?? {} as GetEmployeeAccountByIdResponse;

  const processedLastActivity = useMemo(
    () => formatDate({ date: lastActivity }, DateTimeFormatEnum.DEFAULT),
    [lastActivity],
  );

  if (!employeeAccount || isError) {
    return null;
  }

  return (
    <EntityDetailsContainer
      container
      isLoading={isLoading}
      editable={false}
      justifyContent="space-between"
    >
      <Grid item xs={8} container flexDirection="column" spacing={3}>
        <Grid container item flexDirection="column">
          <Typography variant="caption" color={G600} mb={1}>
            {t('organizationEmployeeDetailsWidget.name')}
          </Typography>
          <TypographyWithTooltip variant="h6" color={G900}>
            {name}
          </TypographyWithTooltip>
        </Grid>
        <Grid container item flexDirection="column">
          <Typography variant="caption" color={G600} mb={1}>
            {t('organizationEmployeeDetailsWidget.email')}
          </Typography>
          <TypographyWithTooltip color={G600}>
            {email}
          </TypographyWithTooltip>
        </Grid>
      </Grid>
      <Grid item xs={4} container flexDirection="column" spacing={3}>
        <Grid container item flexDirection="column" flex={1} alignItems="end">
          <Box>
            <Label
              text={t(EMPLOYEE_ACCOUNT_TYPES_TRANSLATION_KEYS[employeeAccountType])}
              bgColor={S600}
            />
          </Box>
        </Grid>
        <Grid container item flexDirection="column" alignItems="end">
          <Typography variant="caption" color={G600} mb={1}>
            {t('organizationEmployeeDetailsWidget.lastActivity')}
          </Typography>
          <TypographyWithTooltip variant="subtitle1" color={G900}>
            {processedLastActivity}
          </TypographyWithTooltip>
        </Grid>
      </Grid>
    </EntityDetailsContainer>
  );
};

export default memo(OrganizationEmployeeDetailsWidget);
