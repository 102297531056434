import { memo, useCallback } from 'react';
import {
  Grid,
  G900,
  TypographyWithHelp,
  useTranslations,
  WidgetContainer,
} from '@uniqkey-frontend/shared-app';
import {
  GetOrganizationByIdResponse,
  EmployeeAccountStatus,
} from '@uniqkey-backend-partner/api-client';
import { useNavigate, createSearchParams } from 'react-router-dom';
import OrganizationUserDetailsWidgetItem from './components/OrganizationUserDetailsWidgetItem';
import PageRouteEnum from '../../../../../../enums/PageRouteEnum';
import { OrganizationModuleTabEnum } from '../../../OrganizationModule';

interface IOrganizationUserDetailsWidgetProps {
  organizationId: string;
  organization?: GetOrganizationByIdResponse;
  isLoading: boolean;
  isError: boolean;
}

const TITLE_TYPOGRAPHY_PROPS = { variant: 'subtitle1' };

const OrganizationUserDetailsWidget = (props: IOrganizationUserDetailsWidgetProps) => {
  const {
    organizationId, organization, isLoading, isError,
  } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();

  const {
    activatedEmployeesCount,
    provisionedEmployeesCount,
  } = organization ?? {} as GetOrganizationByIdResponse;

  const handleNavigation = useCallback((statusFilter: EmployeeAccountStatus) => {
    const search = createSearchParams({ statusFilter }).toString();
    navigate({
      pathname: `${
        PageRouteEnum.Organizations
      }/${organizationId}/${OrganizationModuleTabEnum.Employees}`,
      search,
    });
  }, [organizationId, navigate]);

  const handleActiveUsersLinkClick = useCallback(() => {
    handleNavigation(EmployeeAccountStatus.Active);
  }, [handleNavigation]);

  const handleInvitedUsersLinkClick = useCallback(() => {
    handleNavigation(EmployeeAccountStatus.Invited);
  }, [handleNavigation]);

  if (isError) {
    return null;
  }

  return (
    <WidgetContainer container pb={3} withShadow isLoading={isLoading} rowGap={2}>
      <Grid item>
        <TypographyWithHelp
          helperText={t('organizationUserDetailsWidget.tooltip')}
          color={G900}
          TypographyProps={TITLE_TYPOGRAPHY_PROPS}
        >
          {t('organizationUserDetailsWidget.title')}
        </TypographyWithHelp>
      </Grid>
      <Grid item container>
        <OrganizationUserDetailsWidgetItem
          onClick={handleActiveUsersLinkClick}
          title={t('organizationUserDetailsWidget.activeUsers.title')}
          titleHelperText={t('organizationUserDetailsWidget.activeUsers.tooltip')}
          linkText={t('organizationUserDetailsWidget.link')}
          count={activatedEmployeesCount}
          showDivider
        />
        <OrganizationUserDetailsWidgetItem
          onClick={handleInvitedUsersLinkClick}
          title={t('organizationUserDetailsWidget.invitedUsers.title')}
          titleHelperText={t('organizationUserDetailsWidget.invitedUsers.tooltip')}
          linkText={t('organizationUserDetailsWidget.link')}
          count={provisionedEmployeesCount}
          showDivider={false}
        />
      </Grid>
    </WidgetContainer>
  );
};

export default memo(OrganizationUserDetailsWidget);
