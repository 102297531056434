import {
  useTranslations,
  useHeroSnackbar,
  WidgetContainer,
  Grid,
  Typography,
  List,
  Button,
  RenderIf,
} from '@uniqkey-frontend/shared-app';
import { useNavigate } from 'react-router-dom';
import { useState, useCallback, memo } from 'react';
import { useGetGroupLatestOrganizationsForAdmin } from '../../../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../../../enums/PageRouteEnum';
import { GroupModuleTabEnum } from '../../../GroupModule';
import GroupOrganizationsSelectorModal from '../../../GroupOrganizationsSelectorModal';
import AddOrganizationForAdminToGroupWidgetListItem
  from './components/AddOrganizationForAdminToGroupWidgetListItem';
import AddOrganizationToGroupWidgetNoData from '../AddOrganizationToGroupWidgetNoData';

interface IAddOrganizationForAdminToGroupWidgetProps {
  groupId: string;
}

const AddOrganizationForAdminToGroupWidget = (
  props: IAddOrganizationForAdminToGroupWidgetProps,
) => {
  const { groupId } = props;

  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const navigate = useNavigate();

  const {
    data, isLoading, isError,
  } = useGetGroupLatestOrganizationsForAdmin({
    groupId,
    page: 1,
    pageLength: 2,
  }, {
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const [
    isGroupOrganizationsSelectorModalOpen,
    setIsGroupOrganizationsSelectorModalOpen,
  ] = useState(false);
  const handleGroupOrganizationsSelectorModalOpen = useCallback(
    () => setIsGroupOrganizationsSelectorModalOpen(true),
    [],
  );
  const handleGroupOrganizationsSelectorModalClose = useCallback(
    () => setIsGroupOrganizationsSelectorModalOpen(false),
    [],
  );

  const handleNavigation = useCallback(() => {
    navigate({
      pathname: `${PageRouteEnum.Groups}/${groupId}/${GroupModuleTabEnum.Organizations}`,
    });
  }, [groupId, navigate]);

  const { data: preOrganizations } = data ?? {};
  const organizations = preOrganizations ?? [];

  if (isError) {
    return null;
  }

  if (!organizations.length && !isLoading) {
    return (
      <AddOrganizationToGroupWidgetNoData groupId={groupId} isForAdmin />
    );
  }

  return (
    <WidgetContainer
      container
      flexDirection="column"
      py={3}
      withShadow
      isLoading={isLoading}
    >
      <Grid item>
        <Typography variant="h6">{t('addOrganizationToGroupWidget.title')}</Typography>
      </Grid>
      <List>
        {organizations.map((organization) => (
          <AddOrganizationForAdminToGroupWidgetListItem
            groupId={groupId}
            organization={organization}
            key={organization.organizationId}
          />
        ))}
      </List>
      <Grid container item columnGap={1}>
        <Grid item mt={2}>
          <Button variant="outlined" fullWidth onClick={handleGroupOrganizationsSelectorModalOpen}>
            {t('addOrganizationToGroupWidget.addOrganization')}
          </Button>
        </Grid>
        <RenderIf condition={!!organizations.length}>
          <Grid item mt={2}>
            <Button variant="outlined" fullWidth onClick={handleNavigation}>
              {t('addOrganizationToGroupWidget.viewAll')}
            </Button>
          </Grid>
        </RenderIf>
      </Grid>
      {isGroupOrganizationsSelectorModalOpen && (
        <GroupOrganizationsSelectorModal
          isOpen={isGroupOrganizationsSelectorModalOpen}
          onClose={handleGroupOrganizationsSelectorModalClose}
          groupId={groupId}
          isForAdmin
        />
      )}
    </WidgetContainer>
  );
};

export default memo(AddOrganizationForAdminToGroupWidget);
