import { memo, useCallback, useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  WidgetContainer,
  useHeroSnackbar,
  useTranslations,
  List,
  RenderIf,
} from '@uniqkey-frontend/shared-app';
import { useNavigate } from 'react-router-dom';
import { GroupUserOrderProperty } from '@uniqkey-backend-partner/api-client';
import { useGetGroupUsers } from '../../../../../../hooks/reactQuery';
import AddUserToGroupWidgetListItem from './components/AddUserToGroupWidgetListItem';
import PageRouteEnum from '../../../../../../enums/PageRouteEnum';
import { GroupModuleTabEnum } from '../../../GroupModule';
import AddUserToGroupWidgetNoData from './components/AddUserToGroupWidgetNoData';
import GroupUsersSelectorModal from '../../../GroupUsersSelectorModal';
import ACLEnum from '../../../../../../enums/ACLEnum';
import { useUser } from '../../../../../../contexts/UserContext';

interface IAddUserToGroupWidgetProps {
  groupId: string;
}

const AddUserToGroupWidget = (props: IAddUserToGroupWidgetProps) => {
  const { groupId } = props;

  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const navigate = useNavigate();
  const { userCan } = useUser();

  const {
    data, isLoading, isError,
  } = useGetGroupUsers({
    groupId,
    page: 1,
    pageLength: 2,
    orderPropertyName: GroupUserOrderProperty.CreatedAt,
    isDescending: true,
  }, {
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const [isGroupUsersSelectorModalOpen, setIsGroupUsersSelectorModalOpen] = useState(false);
  const handleGroupUsersSelectorModalOpen = useCallback(
    () => setIsGroupUsersSelectorModalOpen(true),
    [],
  );
  const handleGroupUsersSelectorModalClose = useCallback(
    () => setIsGroupUsersSelectorModalOpen(false),
    [],
  );

  const handleNavigation = useCallback(() => {
    navigate({
      pathname: `${PageRouteEnum.Groups}/${groupId}/${GroupModuleTabEnum.Users}`,
    });
  }, [groupId, navigate]);

  const { data: preUsers } = data ?? {};
  const users = preUsers ?? [];

  if (isError) {
    return null;
  }

  if (!users.length && !isLoading) {
    return (
      <AddUserToGroupWidgetNoData groupId={groupId} />
    );
  }

  return (
    <WidgetContainer
      container
      flexDirection="column"
      py={3}
      withShadow
      isLoading={isLoading}
    >
      <Grid item>
        <Typography variant="h6">{t('addUserToGroupWidget.title')}</Typography>
      </Grid>
      <List>
        {users.map((user) => (
          <AddUserToGroupWidgetListItem
            groupId={groupId}
            user={user}
            key={user.partnerUserId}
          />
        ))}
      </List>
      <Grid container item columnGap={1}>
        <RenderIf condition={userCan(ACLEnum.GroupAddUsers)}>
          <Grid item mt={2}>
            <Button variant="outlined" fullWidth onClick={handleGroupUsersSelectorModalOpen}>
              {t('addUserToGroupWidget.addUser')}
            </Button>
          </Grid>
        </RenderIf>
        <RenderIf condition={!!users.length}>
          <Grid item mt={2}>
            <Button variant="outlined" fullWidth onClick={handleNavigation}>
              {t('addUserToGroupWidget.viewAll')}
            </Button>
          </Grid>
        </RenderIf>
      </Grid>
      {isGroupUsersSelectorModalOpen && (
        <GroupUsersSelectorModal
          isOpen={isGroupUsersSelectorModalOpen}
          onClose={handleGroupUsersSelectorModalClose}
          groupId={groupId}
        />
      )}
    </WidgetContainer>
  );
};

export default memo(AddUserToGroupWidget);
