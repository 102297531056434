import { memo, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useHeroSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { GetPartnerByIdResponse } from '@uniqkey-backend-partner/api-client';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { useGetPartnerById } from '../../../../hooks/reactQuery';
import { parseIdParam } from '../../../../helpers/parseIdParam';

interface IPartnerPartnerPageBreadcrumbsProps {
  partnerId1: string;
  partnerId2: string;
  partnerId3: string;
}

interface IPartnerConfig {
  url: string;
  partnerInfo: GetPartnerByIdResponse;
}

const PartnerPartnerPageBreadcrumbs = (props: IPartnerPartnerPageBreadcrumbsProps) => {
  const { partnerId1, partnerId2, partnerId3 } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useHeroSnackbar();

  const { parsedPartnerId1, parsedPartnerId2, parsedPartnerId3 } = useMemo(() => ({
    parsedPartnerId1: parseIdParam(partnerId1),
    parsedPartnerId2: parseIdParam(partnerId2),
    parsedPartnerId3: parseIdParam(partnerId3),
  }), [partnerId1, partnerId2, partnerId3]);

  const {
    data: partner1,
    isLoading: isLoading1,
    isError: isPartnerError1,
  } = useGetPartnerById(
    { partnerId: partnerId1 },
    { enabled: !!parsedPartnerId1 },
  );

  const {
    data: partner2,
    isLoading: isLoading2,
    isError: isPartnerError2,
  } = useGetPartnerById(
    { partnerId: partnerId2 },
    { enabled: !!parsedPartnerId2 },
  );

  const {
    data: partner3,
    isLoading: isLoading3,
    isError: isPartnerError3,
  } = useGetPartnerById(
    { partnerId: partnerId3 },
    { enabled: !!parsedPartnerId3 },
  );

  const isLoading = isLoading1 || isLoading2 || isLoading3;
  const isError = isPartnerError1 || isPartnerError2 || isPartnerError3;

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading) {
      return [];
    }
    const partnersHomeNode = {
      title: t('navigation.partners'),
      onClick: () => navigate(PageRouteEnum.Partners),
    };
    const prePartnersNode = [
      {
        partnerInfo: partner1,
        url: `${PageRouteEnum.Partners}/${partnerId1}`,
      },
      {
        partnerInfo: partner2,
        url: `${PageRouteEnum.PartnersDeep}/${partnerId1}/${partnerId2}/${null}`,
      },
      {
        partnerInfo: partner3,
        url: `${PageRouteEnum.PartnersDeep}/${partnerId1}/${partnerId2}/${partnerId3}`,
      },
    ].filter((partnerConfig): partnerConfig is IPartnerConfig => !!partnerConfig.partnerInfo);
    const partnersNode = prePartnersNode.map((partnerConfig, index) => {
      if (prePartnersNode.length === index + 1) {
        return { title: partnerConfig.partnerInfo.name };
      }
      return {
        title: partnerConfig.partnerInfo.name,
        onClick: () => navigate(partnerConfig.url),
      };
    });
    return [
      partnersHomeNode,
      ...partnersNode,
    ];
  }, [isLoading, partner1, partner2, partner3, partnerId1, partnerId2, partnerId3, navigate, t]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isError) {
      showError({ text: t('common.somethingWentWrong') });
      navigate(PageRouteEnum.Partners);
    }
  }, [isLoading, isError, navigate, showError, t]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(PartnerPartnerPageBreadcrumbs);
