import {
  memo, useCallback, useState, useMemo,
} from 'react';
import {
  PanelContent,
  Grid,
  Divider,
  Button,
  useTranslations,
  InviteUserIcon,
  Tooltip,
  ActionButton,
  DeleteIcon,
  useMapKeyValueExtractor,
  useHeroSnackbar,
  PromptModal,
  RenderIf,
} from '@uniqkey-frontend/shared-app';
import useGroupUsersTable from '../../../../hooks/tables/useGroupUsersTable';
import GroupUsersTable, {
  IGroupUsersTableRow,
} from '../../../../components/tables/GroupUsersTable';
import GroupUsersSelectorModal from '../GroupUsersSelectorModal';
import { useRemoveUsersFromGroup } from '../../../../hooks/reactQuery';
import { logException } from '../../../../services/sentryService';
import ACLEnum from '../../../../enums/ACLEnum';
import { useUser } from '../../../../contexts/UserContext';

interface IGroupUsersTabProps {
  groupId: string;
}

const GroupUsersTab = (props: IGroupUsersTabProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useHeroSnackbar();
  const { userCan } = useUser();

  const [isGroupUsersSelectorModalOpen, setIsGroupUsersSelectorModalOpen] = useState(false);
  const [isRemoveUsersFromGroupModalOpen, setIsRemoveUsersFromGroupModalOpen] = useState(false);

  const handleGroupUsersSelectorModalOpen = useCallback(
    () => setIsGroupUsersSelectorModalOpen(true),
    [],
  );
  const handleGroupUsersSelectorModalClose = useCallback(
    () => setIsGroupUsersSelectorModalOpen(false),
    [],
  );

  const handleRemoveUsersFromGroupModalOpen = useCallback(
    () => setIsRemoveUsersFromGroupModalOpen(true),
    [],
  );

  const handleRemoveUsersFromGroupModalClose = useCallback(
    () => setIsRemoveUsersFromGroupModalOpen(false),
    [],
  );

  const {
    selectedGroupUsers,
    resetSelectedRows,
    resetActivePage,
    ...restTableProps
  } = useGroupUsersTable({
    persistentFilters: { groupId },
    noDataMessageKey: 'table.noData.default',
  });

  const canAddUsers = userCan(ACLEnum.GroupAddUsers);
  const canRemoveUsers = userCan(ACLEnum.GroupRemoveUsers);

  const TABLE_OPTIONS = useMemo(() => ({
    selection: canRemoveUsers,
  }), [canRemoveUsers]);

  const {
    mutate: mutateRemoveUsersFromGroup,
    isLoading: isRemoveUsersFromGroupLoading,
  } = useRemoveUsersFromGroup();

  const {
    values: selectedUsersAsObjects, keys: selectedUsersIds,
  } = useMapKeyValueExtractor<IGroupUsersTableRow>(selectedGroupUsers);

  const handleRemoveUsersFromGroup = useCallback(async () => {
    mutateRemoveUsersFromGroup(
      { groupId, partnerUserIds: selectedUsersIds },
      {
        onSuccess: ({ failCount, successCount }) => {
          if (successCount) {
            showSuccess({
              text: t(
                'removeUsersFromGroupModal.successMessage',
                { count: successCount },
              ),
            });
          }
          if (failCount) {
            showError({ text: t('removeUsersFromGroupModal.errorMessage', { count: failCount }) });
          }
          handleRemoveUsersFromGroupModalClose();
          resetSelectedRows();
          resetActivePage();
        },
        onError: (e) => {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, { message: 'GroupUsersTab/handleRemoveUsersFromGroup exception' });
        },
      },
    );
  }, [
    mutateRemoveUsersFromGroup,
    handleRemoveUsersFromGroupModalClose,
    groupId,
    selectedUsersIds,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  return (
    <PanelContent p={0}>
      <RenderIf condition={canAddUsers || canRemoveUsers}>
        <Grid item container flexWrap="nowrap" p={1}>
          <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
            <RenderIf condition={canRemoveUsers}>
              <Grid item my={0.5}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item alignSelf="center">
                <Tooltip title={t('common.remove')}>
                  <ActionButton
                    width={40}
                    height={40}
                    onClick={handleRemoveUsersFromGroupModalOpen}
                    disabled={!selectedUsersAsObjects.length}
                  >
                    <DeleteIcon />
                  </ActionButton>
                </Tooltip>
              </Grid>
              <Grid item my={0.5}>
                <Divider orientation="vertical" />
              </Grid>
            </RenderIf>
          </Grid>
          <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
            <RenderIf condition={canAddUsers}>
              <Button
                icon={<InviteUserIcon />}
                onClick={handleGroupUsersSelectorModalOpen}
              >
                {t('groupPage.usersTab.addUserButton')}
              </Button>
            </RenderIf>
          </Grid>
        </Grid>
        <Divider />
      </RenderIf>
      <GroupUsersTable
        selectedGroupUsers={selectedGroupUsers}
        options={TABLE_OPTIONS}
        {...restTableProps}
      />
      {isGroupUsersSelectorModalOpen && (
        <GroupUsersSelectorModal
          isOpen={isGroupUsersSelectorModalOpen}
          onClose={handleGroupUsersSelectorModalClose}
          groupId={groupId}
        />
      )}
      {isRemoveUsersFromGroupModalOpen && (
        <PromptModal
          open={isRemoveUsersFromGroupModalOpen}
          onClose={handleRemoveUsersFromGroupModalClose}
          onSubmit={handleRemoveUsersFromGroup}
          title={t(
            'removeUsersFromGroupModal.title',
            { count: selectedGroupUsers.size },
          )}
          description={t('removeUsersFromGroupModal.description')}
          approvalButtonText="common.remove"
          list={selectedUsersAsObjects}
          renderField="name"
          renderKey="partnerUserId"
          isLoading={isRemoveUsersFromGroupLoading}
        />
      )}
    </PanelContent>
  );
};

export default memo(GroupUsersTab);
