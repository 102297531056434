import { useCallback, useState, useMemo } from 'react';
import {
  Grid,
  Panel,
  Box,
  Button,
  FilterButton,
  useTranslations,
  SearchableTextField,
  Tooltip,
  InviteUserIcon,
  useHeroSnackbar,
  ActionButton,
  Divider,
  DeleteIcon,
  useMapKeyValueExtractor,
  PromptModal,
  usePopper,
  RenderIf,
  ShareIcon,
  derivePasswordKey,
  ISearchableTextFieldProps,
  IFilterButtonProps,
  encryptAsymmetric,
  BaseEitherError,
} from '@uniqkey-frontend/shared-app';
import {
  MeResponse,
  KeysState,
} from '@uniqkey-backend-partner/api-client';
import ACLEnum from '../../enums/ACLEnum';
import { useUser } from '../../contexts/UserContext';
import usePartnerUsersForAdminTable from '../../hooks/tables/usePartnerUsersForAdminTable';
import PartnerUsersForAdminTable, {
  IPartnerUsersForAdminTableRow,
} from '../../components/tables/PartnerUsersForAdminTable';
import usePartnerUsersAPI from '../../hooks/usePartnerUsersAPI';
import { useDeletePartnerUsers, useLanguages } from '../../hooks/reactQuery';
import PartnerUsersForAdminListFilter, {
  IPartnerUsersForAdminListFilterSubmitResult,
} from './components/PartnerUsersForAdminListFilter';
import CreatePartnerUserModal, {
  ICreatePartnerUserModalFormValue,
} from '../PartnerUsersPage/components/CreatePartnerUserModal';
import SharePartnerPrivateKeyModal, {
  ISharePartnerPrivateKeyModalFormValue,
} from './components/SharePartnerPrivateKeyModal';
import { logException } from '../../services/sentryService';
import KeysManager from '../../services/keysManager';
import { generateTooltipTitle } from '../../helpers/tooltips';

const SUCH_PARTNER_USER_ALREADY_EXISTS_ERROR = 'Such_PartnerUser_already_exists';

const PartnerUsersForAdminPage = () => {
  const { t } = useTranslations();
  const { createPartnerUser } = usePartnerUsersAPI();
  const { showSuccess, showError } = useHeroSnackbar();
  const { currentUser, userCan } = useUser();
  const {
    partnerId, role, id: currentUserId, hasPartnerKeys,
  } = currentUser ?? {} as MeResponse;

  const [isCreatePartnerUserModalOpen, setIsCreatePartnerUserModalOpen] = useState(false);
  const [isCreatePartnerUserLoading, setIsCreatePartnerUserLoading] = useState(false);
  const [isDeletePartnerUsersModalOpen, setIsDeletePartnerUsersModalOpen] = useState(false);
  const [
    isSharePartnerPrivateKeyModalOpen,
    setIsSharePartnerPrivateKeyModalOpen,
  ] = useState(false);
  const [isSharePartnerPrivateKeyLoading, setIsSharePartnerPrivateKeyLoading] = useState(false);

  // preload for create partner user modal
  useLanguages({ includeOnlyAllowedLanguages: false });

  const {
    selectedPartnerUsersForAdmin,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    resetSelectedRows,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetQuery,
    ...restTableProps
  } = usePartnerUsersForAdminTable({
    noDataMessageKey: 'partnerUsersForAdminPage.noData',
  });

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const {
    values: selectedPartnerUsersForAdminAsObjects, keys: selectedPartnerUsersForAdminIds,
  } = useMapKeyValueExtractor<
    IPartnerUsersForAdminTableRow
  >(selectedPartnerUsersForAdmin);

  const {
    mutate: deletePartnerUsers,
    isLoading: isDeletePartnerUsersLoading,
  } = useDeletePartnerUsers({ isForAdmin: true });

  const handleCreatePartnerUserModalOpen = useCallback(
    () => setIsCreatePartnerUserModalOpen(true),
    [],
  );

  const handleCreatePartnerUserModalClose = useCallback(
    () => setIsCreatePartnerUserModalOpen(false),
    [],
  );

  const handleDeletePartnerUsersModalOpen = useCallback(
    () => setIsDeletePartnerUsersModalOpen(true),
    [],
  );

  const handleDeletePartnerUsersModalClose = useCallback(
    () => setIsDeletePartnerUsersModalOpen(false),
    [],
  );

  const handleSharePartnerPrivateKeyModalOpen = useCallback(
    () => setIsSharePartnerPrivateKeyModalOpen(true),
    [],
  );

  const handleSharePartnerPrivateKeyModalClose = useCallback(
    () => setIsSharePartnerPrivateKeyModalOpen(false),
    [],
  );

  const isCurrentUserSelected = selectedPartnerUsersForAdmin.has(currentUserId);
  const canDeletePartnerUser = userCan(ACLEnum.PartnerUserDelete);

  const {
    isDeletePartnerUserDisabled,
    isSharePartnerKeysDisabled,
  } = useMemo(() => {
    if (!selectedPartnerUsersForAdminAsObjects.length || isCurrentUserSelected) {
      return {
        isDeletePartnerUserDisabled: true,
        isSharePartnerKeysDisabled: true,
      };
    }
    let disableDeletePartnerUser = false;
    let disableSharePartnerKeys = false;
    if (!canDeletePartnerUser) {
      disableDeletePartnerUser = true;
    }
    if (hasPartnerKeys) {
      disableSharePartnerKeys = selectedPartnerUsersForAdminAsObjects.some(
        ({ keysState }) => keysState !== KeysState.PartnerUserToPartnerKeyUnavailable,
      );
    } else {
      disableSharePartnerKeys = true;
    }
    return {
      isDeletePartnerUserDisabled: disableDeletePartnerUser,
      isSharePartnerKeysDisabled: disableSharePartnerKeys,
    };
  }, [
    canDeletePartnerUser,
    hasPartnerKeys,
    isCurrentUserSelected,
    selectedPartnerUsersForAdminAsObjects,
  ]);

  const {
    deletePartnerUserTooltipTitle,
    sharePartnerKeysTooltipTitle,
  } = useMemo(() => {
    const deletePartnerUserTitle = generateTooltipTitle({
      selectedDataLength: selectedPartnerUsersForAdminAsObjects.length,
      t,
      isDisabled: isDeletePartnerUserDisabled,
      key: 'partnerUsersForAdminPage.delete',
    });
    const sharePartnerKeysTitle = generateTooltipTitle({
      selectedDataLength: selectedPartnerUsersForAdminAsObjects.length,
      t,
      isDisabled: isSharePartnerKeysDisabled,
      key: 'partnerUsersForAdminPage.share',
    });

    return {
      deletePartnerUserTooltipTitle: deletePartnerUserTitle,
      sharePartnerKeysTooltipTitle: sharePartnerKeysTitle,
    };
  }, [
    selectedPartnerUsersForAdminAsObjects.length,
    t,
    isSharePartnerKeysDisabled,
    isDeletePartnerUserDisabled,
  ]);

  const TABLE_OPTIONS = useMemo(() => ({
    selection: canDeletePartnerUser,
  }), [canDeletePartnerUser]);

  const handleCreatePartnerUser = useCallback(async (
    partnerUser: ICreatePartnerUserModalFormValue,
  ) => {
    try {
      setIsCreatePartnerUserLoading(true);
      await createPartnerUser({ ...partnerUser, partnerId });
      showSuccess({
        text: t('createPartnerUserModal.partnerUserCreated'),
      });
      handleCreatePartnerUserModalClose();
      resetQuery();
    } catch (e: any) {
      let key = 'common.somethingWentWrong';
      if (e?.response?.data?.includes(SUCH_PARTNER_USER_ALREADY_EXISTS_ERROR)) {
        key = 'createPartnerUserModal.suchPartnerUserAlreadyExists';
      }
      showError({ text: t(key) });
      logException(e, {
        message: 'PartnerUsersForAdminPage/handleCreatePartnerUser exception',
      });
    } finally {
      setIsCreatePartnerUserLoading(false);
    }
  }, [
    partnerId,
    createPartnerUser,
    handleCreatePartnerUserModalClose,
    resetQuery,
    showError,
    showSuccess,
    t,
  ]);

  const handleDeletePartnerUsers = useCallback(async () => {
    deletePartnerUsers({
      partnerUserIds: selectedPartnerUsersForAdminIds,
    }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t(
              'deletePartnerUsersModal.successMessage',
              { count: successCount },
            ),
          });
        }
        if (failCount) {
          showError({ text: t('deletePartnerUsersModal.errorMessage', { count: failCount }) });
        }
        handleDeletePartnerUsersModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: () => showError({ text: t('common.somethingWentWrong') }),
    });
  }, [
    selectedPartnerUsersForAdminIds,
    deletePartnerUsers,
    handleDeletePartnerUsersModalClose,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((
    updatedValues: IPartnerUsersForAdminListFilterSubmitResult,
  ) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  const handleSharePartnerPrivateKeyError = useCallback((error: Error, from?: string) => {
    const processedFrom = from ? `/${from}` : '';
    logException(error, {
      message: `PartnerUsersForAdminPage/handleSharePartnerPrivateKey${processedFrom} exception`,
    });
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const handleSharePartnerPrivateKey = useCallback(async (
    form: ISharePartnerPrivateKeyModalFormValue,
  ) => {
    try {
      setIsSharePartnerPrivateKeyLoading(true);
      const { password } = form;
      const derivedMasterPassword = await derivePasswordKey(password, currentUserId);

      const fetchPartnerUserKeysResult = await KeysManager
        .PartnerUserKeys.fetchPartnerUserKeysOperation();
      if (fetchPartnerUserKeysResult instanceof BaseEitherError) {
        handleSharePartnerPrivateKeyError(
          fetchPartnerUserKeysResult,
          'fetchPartnerUserKeysOperation',
        );
        return;
      }
      const decryptPartnerUserPrivateKeyResult = KeysManager
        .PartnerUserKeys.decryptPartnerUserPrivateKeyOperation({
          derivedMasterPassword,
          encryptedPrivateKey: fetchPartnerUserKeysResult.privateKey,
        });
      if (decryptPartnerUserPrivateKeyResult instanceof BaseEitherError) {
        showError({ text: t('sharePartnerPrivateKeyModal.incorrectPassword') });
        return;
      }

      const fetchPartnerKeysResult = await KeysManager.PartnerKeys.fetchPartnerKeysOperation();
      if (fetchPartnerKeysResult instanceof BaseEitherError) {
        handleSharePartnerPrivateKeyError(
          fetchPartnerKeysResult,
          'fetchPartnerKeysOperation',
        );
        return;
      }
      if (fetchPartnerKeysResult.privateKey === null) {
        handleSharePartnerPrivateKeyError(
          new Error('Partner keys not shared'),
        );
        return;
      }

      const { privateKey: partnerUserPrivateKey } = decryptPartnerUserPrivateKeyResult;
      const { publicKey: partnerUserPublicKey } = fetchPartnerUserKeysResult;

      const decryptPartnerPrivateKeyResult = await KeysManager
        .PartnerKeys.decryptPartnerPrivateKeyOperation({
          encryptedPrivateKey: fetchPartnerKeysResult.privateKey,
          partnerUserPrivateKey,
          partnerUserPublicKey,
        });
      if (decryptPartnerPrivateKeyResult instanceof BaseEitherError) {
        handleSharePartnerPrivateKeyError(
          decryptPartnerPrivateKeyResult,
          'decryptPartnerPrivateKeyOperation',
        );
        return;
      }

      const { privateKey: partnerPrivateKey } = decryptPartnerPrivateKeyResult;
      const { partnerKeyId } = fetchPartnerKeysResult;

      const fetchPartnerUsersPublicKeysResult = await KeysManager
        .PartnerUserKeys.fetchPartnerUsersPublicKeysOperation({
          publicUserIds: selectedPartnerUsersForAdminIds,
        });
      if (fetchPartnerUsersPublicKeysResult instanceof BaseEitherError) {
        handleSharePartnerPrivateKeyError(
          fetchPartnerUsersPublicKeysResult,
          'fetchPartnerUsersPublicKeysOperation',
        );
        return;
      }

      const { data: fetchedPartnerUsersPublicKeys } = fetchPartnerUsersPublicKeysResult;

      const sharePartnerPrivateKeyData = await Promise.all(
        fetchedPartnerUsersPublicKeys.map(async (partnerUserPublicKeys) => {
          const { publicKey, partnerUserId } = partnerUserPublicKeys;

          const encryptedPrivateKey = await encryptAsymmetric({
            string: partnerPrivateKey,
            publicKey,
          });

          return {
            partnerUserId,
            partnerPrivateKey: encryptedPrivateKey,
          };
        }),
      );

      const sharePartnerPrivateKeyResult = await KeysManager
        .PartnerKeys.sharePartnerPrivateKeyOperation({
          partnerKeyId, data: sharePartnerPrivateKeyData,
        });
      if (sharePartnerPrivateKeyResult instanceof BaseEitherError) {
        handleSharePartnerPrivateKeyError(
          sharePartnerPrivateKeyResult,
          'sharePartnerPrivateKeyOperation',
        );
        return;
      }

      const { successCount, failCount } = sharePartnerPrivateKeyResult;

      if (successCount) {
        showSuccess(
          { text: t('sharePartnerPrivateKeyModal.successMessage', { count: successCount }) },
        );
      }

      if (failCount) {
        showError({ text: t('sharePartnerPrivateKeyModal.errorMessage', { count: failCount }) });
      }

      handleSharePartnerPrivateKeyModalClose();
      resetSelectedRows();
      resetQuery();
    } catch (e: any) {
      handleSharePartnerPrivateKeyError(e, 'catch');
    } finally {
      setIsSharePartnerPrivateKeyLoading(false);
    }
  }, [
    handleSharePartnerPrivateKeyModalClose,
    currentUserId,
    selectedPartnerUsersForAdminIds,
    showError,
    t,
    resetSelectedRows,
    resetQuery,
    showSuccess,
    handleSharePartnerPrivateKeyError,
  ]);

  return (
    <Grid container flexDirection="column" className="min-height-100-percent">
      <Grid item mb={1}>
        <Panel>
          <Box p={1}>
            <Grid container justifyContent="space-between" alignItems="stretch">
              <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
                <Grid item>
                  <Tooltip title={t('common.filter')}>
                    <FilterButton
                      isFilterActive={isFilterActive}
                      numberOfActiveFilters={numberOfActiveFilters}
                      selected={isFilterOpen}
                      onChange={toggleIsFilterOpen}
                    />
                  </Tooltip>
                </Grid>
                <Grid item my={0.5}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item alignSelf="center">
                  <Tooltip title={deletePartnerUserTooltipTitle}>
                    <ActionButton
                      width={40}
                      height={40}
                      onClick={handleDeletePartnerUsersModalOpen}
                      disabled={isDeletePartnerUserDisabled}
                    >
                      <DeleteIcon />
                    </ActionButton>
                  </Tooltip>
                </Grid>
                <Grid item alignSelf="center">
                  <Tooltip title={sharePartnerKeysTooltipTitle}>
                    <ActionButton
                      width={40}
                      height={40}
                      onClick={handleSharePartnerPrivateKeyModalOpen}
                      disabled={isSharePartnerKeysDisabled}
                    >
                      <ShareIcon />
                    </ActionButton>
                  </Tooltip>
                </Grid>
                <Grid item my={0.5}>
                  <Divider orientation="vertical" />
                </Grid>
              </Grid>
              <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
                <Grid item>
                  <SearchableTextField
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder={t('common.search')}
                  />
                </Grid>
                <RenderIf condition={userCan(ACLEnum.PartnerUserCreate)}>
                  <Grid ml={3} item>
                    <Button
                      icon={<InviteUserIcon />}
                      onClick={handleCreatePartnerUserModalOpen}
                    >
                      {t('partnerUsersForAdminPage.createPartnerUserButton')}
                    </Button>
                  </Grid>
                </RenderIf>
              </Grid>
            </Grid>
          </Box>
        </Panel>
      </Grid>
      <Grid item xs>
        <Panel>
          <PartnerUsersForAdminTable
            options={TABLE_OPTIONS}
            selectedPartnerUsersForAdmin={selectedPartnerUsersForAdmin}
            {...restTableProps}
          />
        </Panel>
      </Grid>
      {isCreatePartnerUserModalOpen && (
        <CreatePartnerUserModal
          isOpen={isCreatePartnerUserModalOpen}
          isLoading={isCreatePartnerUserLoading}
          onSubmit={handleCreatePartnerUser}
          onClose={handleCreatePartnerUserModalClose}
          role={role}
        />
      )}
      {isDeletePartnerUsersModalOpen && (
        <PromptModal
          open={isDeletePartnerUsersModalOpen}
          onClose={handleDeletePartnerUsersModalClose}
          onSubmit={handleDeletePartnerUsers}
          title={t(
            'deletePartnerUsersModal.title',
            { count: selectedPartnerUsersForAdmin.size },
          )}
          description={t('deletePartnerUsersModal.description')}
          approvalButtonText="common.delete"
          list={selectedPartnerUsersForAdminAsObjects}
          renderField="name"
          renderKey="partnerUserId"
          isLoading={isDeletePartnerUsersLoading}
        />
      )}
      {isSharePartnerPrivateKeyModalOpen && (
        <SharePartnerPrivateKeyModal
          isOpen={isSharePartnerPrivateKeyModalOpen}
          onClose={handleSharePartnerPrivateKeyModalClose}
          isLoading={isSharePartnerPrivateKeyLoading}
          onSubmit={handleSharePartnerPrivateKey}
        />
      )}
      <PartnerUsersForAdminListFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
        role={role}
      />
    </Grid>
  );
};

export default PartnerUsersForAdminPage;
