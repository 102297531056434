import { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  minLengthValidator,
  maxLengthValidator,
  Panel,
  G900,
  Typography,
  useHeroSnackbar,
  useTranslations,
  validateDigits,
  ReactHookFormTextField,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { logException } from '../../services/sentryService';
import { loginTwoFA } from '../../services/authService';
import { useUser } from '../../contexts/UserContext';
import UnauthenticatedStepHelperSection from '../../components/UnauthenticatedStepHelperSection';

interface ILoginTwoFAFormValue {
  enterTwoFA: string;
}

const MIN_WIDTH = 720;
const MIN_HEIGHT = 414;

const LoginTwoFAPage = () => {
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { currentUser } = useUser();
  const { name } = currentUser ?? {};

  const [isLoginTwoFALoading, setIsLoginTwoFALoading] = useState(false);

  const {
    control,
    formState: {
      errors, isDirty, isSubmitted, isValid,
    },
    handleSubmit,
  } = useForm<ILoginTwoFAFormValue>({
    defaultValues: {
      enterTwoFA: '',
    },
  });

  const handleOnSubmit = useCallback(async (form: ILoginTwoFAFormValue) => {
    try {
      setIsLoginTwoFALoading(true);
      const { enterTwoFA: otpCode } = form;
      await loginTwoFA({ otpCode });
      // do not set 'isLoading' to 'false' here so the submit button remains disabled
    } catch (e) {
      setIsLoginTwoFALoading(false);
      showError({ text: t('loginTwoFAPage.errorMessage') });
      logException(e, {
        message: 'LoginTwoFAPage/handleOnSubmit exception',
      });
    }
  }, [showError, t]);

  return (
    <Grid
      container
      className="min-height-100-vh"
      alignItems="center"
      justifyContent="center"
    >
      <Box my={5}>
        <Panel>
          <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete="off" id="2fa-form">
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              flexDirection="column"
              rowGap={5}
              p={4}
              minWidth={MIN_WIDTH}
              minHeight={MIN_HEIGHT}
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                rowGap={2}
              >
                <Grid item>
                  <Typography variant="h4" color={G900}>
                    {t('loginTwoFAPage.title', { name })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography align="center" color={G900}>
                    {t('loginTwoFAPage.description')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={10}>
                <ReactHookFormTextField
                  id="2fa-form-input"
                  control={control}
                  name="enterTwoFA"
                  autoFocus
                  fullWidth
                  type="text"
                  error={!!errors.enterTwoFA}
                  label={t('loginTwoFAPage.enterTwoFA.label')}
                  placeholder={t('loginTwoFAPage.enterTwoFA.placeholder')}
                  helperText={errors.enterTwoFA?.message}
                  rules={{
                    validate: {
                      digit: (twoFA) => validateDigits(twoFA) || t('validation.invalidFormat'),
                      minLength: (
                        twoFA,
                      ) => minLengthValidator(6, twoFA) || t('validation.invalidFormat'),
                      maxLength: (
                        twoFA,
                      ) => maxLengthValidator(6, twoFA) || t('validation.invalidFormat'),
                    },
                  }}
                />
              </Grid>
              <Grid item container xs={4}>
                <Button
                  isLoading={isLoginTwoFALoading}
                  type="submit"
                  fullWidth
                  disabled={!isDirty || (isSubmitted && !isValid)}
                >
                  {t('loginTwoFAPage.loginButton')}
                </Button>
              </Grid>
              <UnauthenticatedStepHelperSection />
            </Grid>
          </form>
        </Panel>
      </Box>
    </Grid>
  );
};

export default LoginTwoFAPage;
