import { useCallback } from 'react';
import {
  useHeroSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetRolesParams,
  TGetRolesMethod,
} from '../../usePermissionsAPI/interfaces';
import { IRolesTableRow } from '../../../components/tables/RolesTable';
import usePermissionsAPI from '../../usePermissionsAPI';

export const REACT_QUERY_ROLES_KEY = [ReactQueryKeyEnum.Roles];

export interface IUseRolesTableParams {
  noDataMessageKey: string;
}

export interface IUseRolesTableReturn extends
  Omit<IUseTableReturn<IRolesTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseQueryTableFetchReturn<TGetRolesMethod>, 'data' | 'isFetchedAfterMount'>
{
  roles: IUseQueryTableFetchReturn<TGetRolesMethod>['data'];
  selectedRoles: IUseTableReturn<IRolesTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetRolesParams, 'page' | 'pageLength'>
{
  activePage: IGetRolesParams['page'];
  perPage: IGetRolesParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
};

const useRolesTable = (params: IUseRolesTableParams): IUseRolesTableReturn => {
  const {
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getRoles } = usePermissionsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    selectedRows: selectedRoles,
    resetTableMethods,
    ...restTableProps
  } = useTable<IRolesTableRow>({
    initialActivePage,
    initialPerPage,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: roles, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_ROLES_KEY,
    queryClient,
    request: getRoles,
    params: {
      page: activePage,
      pageLength: perPage,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
  });

  return {
    roles,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    selectedRoles,
    localization,
    ...restTableProps,
  };
};

export default useRolesTable;
