import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useHeroSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { GroupOrderProperty } from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import { IGetGroupsParams, TGetGroupsMethod } from '../../useGroupsAPI/interfaces';
import { IGroupsTableRow } from '../../../components/tables/GroupsTable';
import useGroupsAPI from '../../useGroupsAPI';

export const REACT_QUERY_GROUPS_KEY = [ReactQueryKeyEnum.Groups];

export interface IUseGroupsTableParams {
  noDataMessageKey: string;
}

export interface IUseGroupsTableReturn extends
  Omit<IUseTableReturn<IGroupsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseQueryTableFetchReturn<TGetGroupsMethod>, 'data' | 'isFetchedAfterMount'>
{
  groups: IUseQueryTableFetchReturn<TGetGroupsMethod>['data'];
  selectedGroups: IUseTableReturn<IGroupsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetGroupsParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetGroupsParams['searchQuery']>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetGroupsParams,
    'page' | 'pageLength' | 'partnerId' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<IGroupsTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetGroupsParams['page'];
  perPage: IGetGroupsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: GroupOrderProperty },
  ),
  columnOrderDirection: true,
};

const useGroupsTable = (
  params: IUseGroupsTableParams,
): IUseGroupsTableReturn => {
  const {
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getGroups } = useGroupsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetGroupsParams['searchQuery']
  >(() => initialSearchQuery);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedGroups,
    resetTableMethods,
    ...restTableProps
  } = useTable<IGroupsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: groups, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_GROUPS_KEY,
    queryClient,
    request: getGroups,
    params: {
      page: activePage,
      pageLength: perPage,
      searchQuery,
      orderPropertyName: GroupOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof GroupOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    groups,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedGroups,
    localization,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useGroupsTable;
