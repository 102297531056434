import {
  useCallback, useState, memo, useMemo,
} from 'react';
import {
  WidgetContainer,
  Button,
  VisualShieldIcon,
  useTranslations,
  useHeroSnackbar,
  Tooltip,
  Grid,
  JumpToAdminPortalIcon,
  openURLInNewTab,
} from '@uniqkey-frontend/shared-app';
import {
  PartnerAccessStatus,
  GetOrganizationPortalAccessInfoResponse,
} from '@uniqkey-backend-partner/api-client';
import config from '../../../../../../config';
import { useUser } from '../../../../../../contexts/UserContext';
import ACLEnum from '../../../../../../enums/ACLEnum';
import {
  useGetOrganizationAccessInfo,
  useGetOrganizationById,
  useGetParentPartner,
} from '../../../../../../hooks/reactQuery';
import usePartnerOrganizationsAPI from '../../../../../../hooks/usePartnerOrganizationsAPI';
import usePartnerUsersAPI from '../../../../../../hooks/usePartnerUsersAPI';
import { logException } from '../../../../../../services/sentryService';

interface IRequestAccessWidgetProps {
  organizationId: string;
}

const ACCESS_STATUS_REACT_QUERY_REFETCH_INTERVAL = 10 * 1000;

const TOOLTIP_TRANSLATIONS_KEYS_BY_ACCESS_STATUS = {
  [PartnerAccessStatus.NoAccess]: 'requestAccessWidget.jumpToAdminPortalButton.tooltip.noAccess',
  [PartnerAccessStatus.NoKeys]: 'requestAccessWidget.jumpToAdminPortalButton.tooltip.noKeys',
  [PartnerAccessStatus.PendingApproval]:
    'requestAccessWidget.jumpToAdminPortalButton.tooltip.pendingAccess',
  [PartnerAccessStatus.Rejected]: 'requestAccessWidget.jumpToAdminPortalButton.tooltip.rejected',
};

const LABEL_TRANSLATIONS_KEYS_BY_ACCESS_STATUS = {
  [PartnerAccessStatus.NoAccess]: 'requestAccessWidget.jumpToAdminPortalButton.label.noAccess',
  [PartnerAccessStatus.NoKeys]: 'requestAccessWidget.jumpToAdminPortalButton.label.noKeys',
  [PartnerAccessStatus.PendingApproval]:
    'requestAccessWidget.jumpToAdminPortalButton.label.pendingAccess',
  [PartnerAccessStatus.Rejected]: 'requestAccessWidget.jumpToAdminPortalButton.label.rejected',
  [PartnerAccessStatus.HasAccess]: 'requestAccessWidget.jumpToAdminPortalButton.label.hasAccess',
};

const RequestAccessWidget = (props: IRequestAccessWidgetProps) => {
  const { organizationId } = props;
  const { showSuccess, showError } = useHeroSnackbar();
  const { t } = useTranslations();
  const { userCan } = useUser();
  const { requestOrganizationLogin } = usePartnerOrganizationsAPI();
  const { getJumpToken } = usePartnerUsersAPI();

  const [isRequestOrganizationLoginLoading, setIsRequestOrganizationLoginLoading] = useState(false);
  const [isRequestAccessButtonClicked, setIsRequestAccessButtonClicked] = useState(false);

  const {
    data: organization,
    isLoading: isOrganizationLoading,
    isError: isOrganizationError,
  } = useGetOrganizationById(
    { organizationId },
  );

  const { canRequestAccess } = organization ?? {};
  const {
    data: partner,
    isLoading: isPartnerLoading,
    isError: isPartnerError,
  } = useGetParentPartner(
    { enabled: isOrganizationLoading ? false : !canRequestAccess },
  );

  const {
    data: organizationAccessInfo,
    isLoading: isOrganizationAccessInfoLoading,
    isError: isOrganizationAccessInfoError,
    refetch: refetchOrganizationAccessInfo,
  } = useGetOrganizationAccessInfo(
    { organizationId },
    {
      refetchInterval: ACCESS_STATUS_REACT_QUERY_REFETCH_INTERVAL,
    },
  );
  const { accessStatus } = organizationAccessInfo ?? {} as GetOrganizationPortalAccessInfoResponse;

  const handleRequestOrganizationLogin = useCallback(async () => {
    try {
      setIsRequestAccessButtonClicked(true);
      setIsRequestOrganizationLoginLoading(true);
      await requestOrganizationLogin(organizationId);
      showSuccess({ text: t('requestAccessWidget.successMessage') });
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      setIsRequestAccessButtonClicked(false);
      logException(e, {
        message: 'RequestAccessWidget/handleRequestOrganizationLogin exception',
      });
    } finally {
      setIsRequestOrganizationLoginLoading(false);
      await refetchOrganizationAccessInfo();
    }
  }, [
    organizationId,
    refetchOrganizationAccessInfo,
    requestOrganizationLogin,
    showError,
    showSuccess,
    t,
  ]);

  const handleJumpToAdminPortal = useCallback(async () => {
    try {
      const { token } = await getJumpToken(organizationId);
      const redirectUrl = config.getSupportJumpUrl(organizationId, token);
      openURLInNewTab({ url: redirectUrl, target: 'OPSupportTab' });
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'RequestAccessWidget/handleJumpToAdminPortal exception',
      });
    }
  }, [getJumpToken, organizationId, showError, t]);

  const isRequestAccessButtonActive = accessStatus === PartnerAccessStatus.NoAccess
    || accessStatus === PartnerAccessStatus.Rejected;

  const isJumpToAdminPortalButtonActive = accessStatus === PartnerAccessStatus.HasAccess;

  const isLoading = isOrganizationAccessInfoLoading || isOrganizationLoading || isPartnerLoading;

  const { name: partnerName } = partner ?? {};
  const statusTooltip = useMemo(() => {
    if (isLoading) {
      return null;
    }
    if (isJumpToAdminPortalButtonActive) {
      return null;
    }
    if (!canRequestAccess) {
      return t(
        'requestAccessWidget.jumpToAdminPortalButton.tooltip.organizationNotRelatedToPartner',
        { partnerName },
      );
    }
    return t(TOOLTIP_TRANSLATIONS_KEYS_BY_ACCESS_STATUS[accessStatus]);
  }, [isLoading, isJumpToAdminPortalButtonActive, canRequestAccess, partnerName, accessStatus, t]);

  if (isOrganizationAccessInfoError || isOrganizationError || isPartnerError) {
    return null;
  }

  return (
    <WidgetContainer
      container
      paddingY={1}
      paddingX={2}
      justifyContent="space-between"
      withShadow
      isLoading={isLoading}
    >
      {userCan(ACLEnum.RequestOrganizationLogin) && canRequestAccess && (
        <Grid minWidth={220}>
          <Button
            disabled={!isRequestAccessButtonActive || isRequestAccessButtonClicked}
            icon={<VisualShieldIcon />}
            onClick={handleRequestOrganizationLogin}
            isLoading={isRequestOrganizationLoginLoading}
            fullWidth
          >
            {t('requestAccessWidget.requestAccessButtonText')}
          </Button>
        </Grid>
      )}
      <Tooltip title={statusTooltip}>
        <Grid minWidth={250}>
          <Button
            disabled={!isJumpToAdminPortalButtonActive}
            icon={<JumpToAdminPortalIcon />}
            onClick={handleJumpToAdminPortal}
            fullWidth
          >
            {t(LABEL_TRANSLATIONS_KEYS_BY_ACCESS_STATUS[accessStatus])}
          </Button>
        </Grid>
      </Tooltip>
    </WidgetContainer>
  );
};

export default memo(RequestAccessWidget);
