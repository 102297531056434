import {
  memo, useCallback, useState, useMemo,
} from 'react';
import {
  PanelContent,
  Grid,
  Divider,
  Button,
  useTranslations,
  OrganizationIcon,
  useHeroSnackbar,
  useMapKeyValueExtractor,
  Tooltip,
  ActionButton,
  DeleteIcon,
  PromptModal,
  RenderIf,
} from '@uniqkey-frontend/shared-app';
import useGroupOrganizationsTable from '../../../../hooks/tables/useGroupOrganizationsTable';
import GroupOrganizationsTable, {
  IGroupOrganizationsTableRow,
} from '../../../../components/tables/GroupOrganizationsTable';
import { useRemoveOrganizationsFromGroup } from '../../../../hooks/reactQuery';
import { logException } from '../../../../services/sentryService';
import GroupOrganizationsSelectorModal from '../GroupOrganizationsSelectorModal';
import { useUser } from '../../../../contexts/UserContext';
import ACLEnum from '../../../../enums/ACLEnum';

interface IGroupOrganizationsTabProps {
  groupId: string;
}

const GroupOrganizationsTab = (props: IGroupOrganizationsTabProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useHeroSnackbar();
  const { userCan } = useUser();

  const [
    isGroupOrganizationsSelectorModalOpen,
    setIsGroupOrganizationsSelectorModalOpen,
  ] = useState(false);
  const [
    isRemoveOrganizationsFromGroupModalOpen,
    setIsRemoveOrganizationsFromGroupModalOpen,
  ] = useState(false);

  const handleGroupOrganizationsSelectorModalOpen = useCallback(
    () => setIsGroupOrganizationsSelectorModalOpen(true),
    [],
  );
  const handleGroupOrganizationsSelectorModalClose = useCallback(
    () => setIsGroupOrganizationsSelectorModalOpen(false),
    [],
  );

  const handleRemoveOrganizationsFromGroupModalOpen = useCallback(
    () => setIsRemoveOrganizationsFromGroupModalOpen(true),
    [],
  );

  const handleRemoveOrganizationsFromGroupModalClose = useCallback(
    () => setIsRemoveOrganizationsFromGroupModalOpen(false),
    [],
  );

  const {
    selectedGroupOrganizations,
    resetSelectedRows,
    resetActivePage,
    ...restTableProps
  } = useGroupOrganizationsTable({
    persistentFilters: { groupId },
    noDataMessageKey: 'table.noData.default',
  });

  const canAddOrganizations = userCan(ACLEnum.GroupAddOrganizations);
  const canRemoveOrganizations = userCan(ACLEnum.GroupRemoveOrganizations);

  const TABLE_OPTIONS = useMemo(() => ({
    selection: canRemoveOrganizations,
  }), [canRemoveOrganizations]);

  const {
    mutate: mutateRemoveOrganizationsFromGroup,
    isLoading: isRemoveOrganizationsFromGroupLoading,
  } = useRemoveOrganizationsFromGroup({ isForAdmin: false });

  const {
    values: selectedOrganizationsAsObjects, keys: selectedOrganizationsIds,
  } = useMapKeyValueExtractor<IGroupOrganizationsTableRow>(selectedGroupOrganizations);

  const handleRemoveOrganizationsFromGroup = useCallback(async () => {
    mutateRemoveOrganizationsFromGroup(
      { groupId, organizationIds: selectedOrganizationsIds },
      {
        onSuccess: ({ failCount, successCount }) => {
          if (successCount) {
            showSuccess({
              text: t(
                'removeOrganizationsFromGroupModal.successMessage',
                { count: successCount },
              ),
            });
          }
          if (failCount) {
            showError({
              text: t(
                'removeOrganizationsFromGroupModal.errorMessage',
                { count: failCount },
              ),
            });
          }
          handleRemoveOrganizationsFromGroupModalClose();
          resetSelectedRows();
          resetActivePage();
        },
        onError: (e) => {
          showError({ text: t('common.somethingWentWrong') });
          logException(
            e,
            { message: 'GroupOrganizationsTab/handleRemoveOrganizationsFromGroup exception' },
          );
        },
      },
    );
  }, [
    mutateRemoveOrganizationsFromGroup,
    handleRemoveOrganizationsFromGroupModalClose,
    groupId,
    selectedOrganizationsIds,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  return (
    <PanelContent p={0}>
      <RenderIf condition={canAddOrganizations || canRemoveOrganizations}>
        <Grid item container flexWrap="nowrap" p={1}>
          <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
            <RenderIf condition={canRemoveOrganizations}>
              <Grid item my={0.5}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item alignSelf="center">
                <Tooltip title={t('common.remove')}>
                  <ActionButton
                    width={40}
                    height={40}
                    onClick={handleRemoveOrganizationsFromGroupModalOpen}
                    disabled={!selectedOrganizationsAsObjects.length}
                  >
                    <DeleteIcon />
                  </ActionButton>
                </Tooltip>
              </Grid>
              <Grid item my={0.5}>
                <Divider orientation="vertical" />
              </Grid>
            </RenderIf>
          </Grid>
          <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
            <RenderIf condition={canAddOrganizations}>
              <Button
                icon={<OrganizationIcon />}
                onClick={handleGroupOrganizationsSelectorModalOpen}
              >
                {t('groupPage.organizationsTab.addOrganizationButton')}
              </Button>
            </RenderIf>
          </Grid>
        </Grid>
        <Divider />
      </RenderIf>
      <GroupOrganizationsTable
        groupId={groupId}
        selectedGroupOrganizations={selectedGroupOrganizations}
        options={TABLE_OPTIONS}
        {...restTableProps}
      />
      {isGroupOrganizationsSelectorModalOpen && (
        <GroupOrganizationsSelectorModal
          isOpen={isGroupOrganizationsSelectorModalOpen}
          onClose={handleGroupOrganizationsSelectorModalClose}
          groupId={groupId}
          isForAdmin={false}
        />
      )}
      {isRemoveOrganizationsFromGroupModalOpen && (
        <PromptModal
          open={isRemoveOrganizationsFromGroupModalOpen}
          onClose={handleRemoveOrganizationsFromGroupModalClose}
          onSubmit={handleRemoveOrganizationsFromGroup}
          title={t(
            'removeOrganizationsFromGroupModal.title',
            { count: selectedGroupOrganizations.size },
          )}
          description={t('removeOrganizationsFromGroupModal.description')}
          approvalButtonText="common.remove"
          list={selectedOrganizationsAsObjects}
          renderField="name"
          renderKey="organizationId"
          isLoading={isRemoveOrganizationsFromGroupLoading}
        />
      )}
    </PanelContent>
  );
};

export default memo(GroupOrganizationsTab);
