import {
  EmployeeAccountType,
  PartnerUserRole,
  PartnerType,
  EmployeeAccountStatus,
  Ownership,
  KeysState,
  OrganizationAccessStatus,
} from '@uniqkey-backend-partner/api-client';
import {
  S600, G600, E600,
} from '@uniqkey-frontend/shared-app';
import ACLEnum from '../enums/ACLEnum';

export const PARTNER_TYPES_TRANSLATION_KEYS = {
  [PartnerType.UniqKey]: 'partnerTypes.uniqkey',
  [PartnerType.Ambassador]: 'partnerTypes.ambassador',
  [PartnerType.Distributor]: 'partnerTypes.distributor',
  [PartnerType.Sales]: 'partnerTypes.sales',
};

export const EMPLOYEE_ACCOUNT_TYPES_TRANSLATION_KEYS = {
  [EmployeeAccountType.Admin]: 'adminRights.admin',
  [EmployeeAccountType.KeylessAdmin]: 'adminRights.promotedToAdmin',
  [EmployeeAccountType.Employee]: 'adminRights.notAdmin',
  [EmployeeAccountType.Supporter]: 'adminRights.notAdmin',
};

export const USER_ROLES_TRANSLATION_KEYS = {
  [PartnerUserRole.Admin]: 'userRoles.admin',
  [PartnerUserRole.LimitedAdmin]: 'userRoles.limitedAdmin',
  [PartnerUserRole.Partner]: 'userRoles.partner',
  [PartnerUserRole.Support]: 'userRoles.support',
  [PartnerUserRole.Audit]: 'userRoles.audit',
};

export const EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS = {
  [EmployeeAccountStatus.Active]: 'employeeStatuses.active',
  [EmployeeAccountStatus.Invited]: 'employeeStatuses.invited',
  [EmployeeAccountStatus.Archived]: 'employeeStatuses.archived',
  [EmployeeAccountStatus.Unprocessed]: 'employeeStatuses.pendingApproval',
  [EmployeeAccountStatus.Staged]: 'employeeStatuses.staged',
  [EmployeeAccountStatus.MigrationInvited]: 'employeeStatuses.migrationInvited',
  [EmployeeAccountStatus.Migrated]: 'employeeStatuses.migrated',
  [EmployeeAccountStatus.ExistingUnprocessed]: 'employeeStatuses.existingUnprocessed',
};

export const SCIM_TRANSLATION_KEYS = {
  true: 'scim.scim',
  false: 'scim.no',
};

export const OWNERSHIP_TRANSLATION_KEYS = {
  [Ownership.Employees]: 'ownership.employees',
  [Ownership.Groups]: 'ownership.groups',
  [Ownership.Unmanaged]: 'ownership.unmanaged',
};

export const MFA_TRANSLATION_KEYS = {
  true: 'mfa.2FA',
  false: 'mfa.notConfigured',
};

export const ACLS_TRANSLATION_KEYS = {
  [ACLEnum.AuditLogExport]: 'ACLs.auditLogExport',
  [ACLEnum.AuditLogView]: 'ACLs.auditLogView',
  [ACLEnum.EmployeeAccountView]: 'ACLs.employeeAccountView',
  [ACLEnum.EmployeeAccountViewAuditLogs]: 'ACLs.employeeAccountViewAuditLogs',
  [ACLEnum.EmployeeAccountViewVaultPasswords]: 'ACLs.employeeAccountViewVaultPasswords',
  [ACLEnum.GroupAddOrganizations]: 'ACLs.groupAddOrganizations',
  [ACLEnum.GroupAddUsers]: 'ACLs.groupAddUsers',
  [ACLEnum.GroupAdminViewOrganizations]: 'ACLs.groupAdminViewOrganizations',
  [ACLEnum.GroupCreate]: 'ACLs.groupCreate',
  [ACLEnum.GroupDelete]: 'ACLs.groupDelete',
  [ACLEnum.GroupEdit]: 'ACLs.groupEdit',
  [ACLEnum.GroupRemoveOrganizations]: 'ACLs.groupRemoveOrganizations',
  [ACLEnum.GroupRemoveUsers]: 'ACLs.groupRemoveUsers',
  [ACLEnum.GroupView]: 'ACLs.groupView',
  [ACLEnum.OrganizationCreate]: 'ACLs.organizationCreate',
  [ACLEnum.OrganizationEdit]: 'ACLs.organizationEdit',
  [ACLEnum.OrganizationExportList]: 'ACLs.organizationExportList',
  [ACLEnum.OrganizationView]: 'ACLs.organizationView',
  [ACLEnum.OrganizationViewAdmins]: 'ACLs.organizationViewAdmins',
  [ACLEnum.OrganizationViewAuditLogs]: 'ACLs.organizationViewAuditLogs',
  [ACLEnum.OrganizationViewDomains]: 'ACLs.organizationViewDomains',
  [ACLEnum.OrganizationViewLicenseHistory]: 'ACLs.organizationViewLicenseHistory',
  [ACLEnum.PartnerCreate]: 'ACLs.partnerCreate',
  [ACLEnum.PartnerEdit]: 'ACLs.partnerEdit',
  [ACLEnum.PartnerUserCreate]: 'ACLs.partnerUserCreate',
  [ACLEnum.PartnerUserDelete]: 'ACLs.partnerUserDelete',
  [ACLEnum.PartnerUserEdit]: 'ACLs.partnerUserEdit',
  [ACLEnum.PartnerUserEditRoles]: 'ACLs.partnerUserEditRoles',
  [ACLEnum.PartnerUserReset2Fa]: 'ACLs.partnerUserReset2FA',
  [ACLEnum.PartnerUserResetPassword]: 'ACLs.partnerUserResetPassword',
  [ACLEnum.PartnerUserView]: 'ACLs.partnerUserView',
  [ACLEnum.PartnerView]: 'ACLs.partnerView',
  [ACLEnum.PartnerViewLicenseHistory]: 'ACLs.partnerViewLicenseHistory',
  [ACLEnum.PartnerViewOrganizations]: 'ACLs.partnerViewOrganizations',
  [ACLEnum.PermissionView]: 'ACLs.permissionView',
  [ACLEnum.RequestOrganizationLogin]: 'ACLs.requestOrganizationLogin',
  [ACLEnum.OrganizationExtendedView]: 'ACLs.organizationExtendedView',
  [ACLEnum.PartnerDelete]: 'ACLs.partnerDelete',
  [ACLEnum.PartnerMove]: 'ACLs.partnerMove',
  [ACLEnum.OrganizationDelete]: 'ACLs.organizationDelete',
  [ACLEnum.OrganizationMove]: 'ACLs.organizationMove',
  [ACLEnum.OrganizationRestore]: 'ACLs.organizationRestore',
};

export const KEYS_STATE_TRANSLATION_KEYS = {
  [KeysState.AllKeysAvailable]: 'keysState.allKeysAvailable',
  [KeysState.UserKeyUnavailable]: 'keysState.userKeyUnavailable',
  [KeysState.PartnerKeyUnavailable]: 'keysState.partnerKeyUnavailable',
  [KeysState.PartnerUserToPartnerKeyUnavailable]: 'keysState.partnerUserToPartnerKeyUnavailable',
};

export const ORGANIZATION_ACCESS_STATUS_TRANSLATION_KEYS = {
  [OrganizationAccessStatus.Pending]: 'organizationAccessStatus.pending',
  [OrganizationAccessStatus.HasAccess]: 'organizationAccessStatus.hasAccess',
};

export const ORGANIZATION_ACCESS_STATUS_TOOLTIP_TRANSLATION_KEYS = {
  [OrganizationAccessStatus.Pending]: 'organizationAccessStatus.tooltip.pending',
  [OrganizationAccessStatus.HasAccess]: 'organizationAccessStatus.tooltip.hasAccess',
};

export const ORGANIZATION_ACCESS_STATUS_COLORS = {
  [OrganizationAccessStatus.Pending]: G600,
  [OrganizationAccessStatus.HasAccess]: S600,
};

export const TWO_FA_COLORS = {
  On: S600,
  Off: E600,
  Na: G600,
};

export const TWO_FA_TRANSLATION_KEYS = {
  On: 'common.on',
  Off: 'common.off',
  Na: 'common.notAvailableShort',
};

export const UNIQKEY_FINDER_IGNORE_CLASSNAME_PROP = { className: 'uniqkey-finder-ignore' };

export const ORGANIZATION_IS_IN_RETENTION_PERIOD_ERROR = 'Organization_is_in_retention_period';
