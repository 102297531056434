import { memo, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useTranslations,
  validateEmail,
  FormHiddenInput,
  Typography,
  S600,
  ReactHookFormSelect,
  MenuItem,
  emptyStringValidator,
  ReactHookFormTextField,
  TSelectProps,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  CreatePartnerUserRequest,
  PartnerUserRole,
} from '@uniqkey-backend-partner/api-client';
import { USER_ROLE_TO_OPTIONS } from '../../../../helpers/userRole';
import UserRoleSelect from '../UserRoleSelect';
import { useLanguages } from '../../../../hooks/reactQuery';

export interface ICreatePartnerUserModalFormValue extends Pick<
  CreatePartnerUserRequest, 'name' | 'email' | 'role' | 'language'
> {}

interface ICreatePartnerUserModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (params: ICreatePartnerUserModalFormValue) => Promise<void> | void;
  onClose: () => void;
  role: PartnerUserRole;
}

const CreatePartnerUserModal = (props: ICreatePartnerUserModalProps) => {
  const {
    isOpen, isLoading, onSubmit, onClose, role,
  } = props;

  const { t } = useTranslations();
  const { languages, isLoading: areLanguagesLoading } = useLanguages({
    includeOnlyAllowedLanguages: false,
  });

  const {
    handleSubmit,
    formState: { isDirty, errors, touchedFields },
    control,
    setValue,
  } = useForm<ICreatePartnerUserModalFormValue>({
    defaultValues: {
      name: '',
      email: '',
      role: USER_ROLE_TO_OPTIONS[role as keyof typeof USER_ROLE_TO_OPTIONS]?.defaultValue ?? '',
      language: 'da',
    },
    mode: 'all',
  });

  const handleFormSubmit = useCallback((formValue: ICreatePartnerUserModalFormValue) => {
    onSubmit(formValue);
  }, [onSubmit]);

  const handleLanguageChange = useCallback<
    NonNullable<TSelectProps['onChange']>
  >((event) => {
    // the 'Create' button should be enabled directly after the language change, not after blur
    setValue('language', event.target.value as string, { shouldTouch: true });
  }, [setValue]);

  const disabled = !isDirty && !touchedFields.language;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off" noValidate>
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('createPartnerUserModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12}>
              <FormHiddenInput />
              <ReactHookFormTextField
                name="name"
                control={control}
                fullWidth
                autoComplete="none"
                autoFocus
                label={`${t('createPartnerUserModal.name.label')}*`}
                placeholder={t('createPartnerUserModal.name.placeholder')}
                error={!!errors.name}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value as string) ? t('validation.required') : true
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormTextField
                name="email"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('createPartnerUserModal.email.label')}*`}
                placeholder={t('createPartnerUserModal.email.placeholder')}
                error={!!errors.email}
                helperText={errors.email?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateEmail(v as string) || t('validation.invalidEmail'),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <UserRoleSelect
                control={control}
                name="role"
                role={role}
                label={`${t('createPartnerUserModal.role.label')}*`}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Typography variant="body1" color={S600}>
              <span>{t('createPartnerUserModal.languages.label')}</span>
              <span>:</span>
            </Typography>
            <Grid container columnSpacing={2}>
              {
                !areLanguagesLoading && (
                  <Grid item xs>
                    <ReactHookFormSelect
                      name="language"
                      control={control}
                      onChange={handleLanguageChange}
                    >
                      {
                        languages.map((currLanguage) => (
                          <MenuItem key={currLanguage.code} value={currLanguage.code}>
                            {currLanguage.localized_name}
                          </MenuItem>
                        ))
                      }
                    </ReactHookFormSelect>
                  </Grid>
                )
              }
              <Grid item xs={3.5}>
                <Button fullWidth isLoading={isLoading} disabled={disabled} type="submit">
                  {t('common.create')}
                </Button>
              </Grid>
              <Grid item xs={3.5}>
                <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                  {t('common.cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(CreatePartnerUserModal);
