import { useCallback } from 'react';
import {
  useHeroSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetACLsByRoleParams,
  TGetACLsByRoleMethod,
} from '../../usePermissionsAPI/interfaces';
import { IRoleTableRow } from '../../../components/tables/RoleTable';
import usePermissionsAPI from '../../usePermissionsAPI';

export const REACT_QUERY_ROLE_KEY = [ReactQueryKeyEnum.Role];

interface IPersistentFilters extends Pick<IGetACLsByRoleParams, 'role'> {}

export interface IUseRoleTableParams {
  persistentFilters: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseRoleTableReturn extends
  Omit<IUseTableReturn<IRoleTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseQueryTableFetchReturn<TGetACLsByRoleMethod>, 'data' | 'isFetchedAfterMount'>
{
  roleACLs: IUseQueryTableFetchReturn<TGetACLsByRoleMethod>['data'];
  selectedRoleACLs: IUseTableReturn<IRoleTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetACLsByRoleParams, 'page' | 'pageLength' | 'role'>
{
  activePage: IGetACLsByRoleParams['page'];
  perPage: IGetACLsByRoleParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
};

const useRoleTable = (
  params: IUseRoleTableParams,
): IUseRoleTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useHeroSnackbar();
  const { getACLsByRole } = usePermissionsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    selectedRows: selectedRoleACLs,
    resetTableMethods,
    ...restTableProps
  } = useTable<IRoleTableRow>({
    initialActivePage,
    initialPerPage,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: roleACLs, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_ROLE_KEY,
    queryClient,
    request: getACLsByRole,
    params: {
      page: activePage,
      pageLength: perPage,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
  });

  return {
    roleACLs,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    selectedRoleACLs,
    localization,
    ...restTableProps,
  };
};

export default useRoleTable;
