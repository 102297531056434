import { memo, useCallback } from 'react';
import {
  Grid,
  G900,
  Typography,
  TypographyWithCountUp,
  useTranslations,
  WidgetContainer,
} from '@uniqkey-frontend/shared-app';
import { useNavigate } from 'react-router-dom';
import { useGetOrganizationEmployeeAccountById } from '../../../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../../../enums/PageRouteEnum';
import { OrganizationEmployeeModuleTabEnum } from '../../../OrganizationEmployeeModule';

interface IOrganizationEmployeeLoginsWidgetProps {
  employeeAccountId: string;
  organizationId: string;
}

const OrganizationEmployeeLoginsWidget = (props: IOrganizationEmployeeLoginsWidgetProps) => {
  const { employeeAccountId, organizationId } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();

  const {
    data: employeeAccount,
    isError,
    isLoading,
  } = useGetOrganizationEmployeeAccountById(
    { employeeAccountId },
  );

  const handleNavigation = useCallback(() => {
    navigate(`${
      PageRouteEnum.Employee
    }/${organizationId}/${employeeAccountId}/${OrganizationEmployeeModuleTabEnum.Logins}`);
  }, [organizationId, employeeAccountId, navigate]);

  if (!employeeAccount || isError) {
    return null;
  }

  const { logins } = employeeAccount;

  return (
    <WidgetContainer
      container
      alignContent="space-between"
      alignItems="center"
      withShadow
      p={3}
      isLoading={isLoading}
    >
      <Grid item container justifyContent="center">
        <Typography color={G900} variant="caption">
          {t('organizationEmployeeLoginsWidget.title')}
        </Typography>
      </Grid>
      <Grid item container justifyContent="center">
        <TypographyWithCountUp variant="h4" value={logins} />
      </Grid>
      <Grid item container justifyContent="center" onClick={handleNavigation}>
        <Typography asLink variant="subtitle1">
          {t('organizationEmployeeLoginsWidget.view')}
        </Typography>
      </Grid>
    </WidgetContainer>
  );
};

export default memo(OrganizationEmployeeLoginsWidget);
