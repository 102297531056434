import {
  memo, useCallback, useState, useMemo,
} from 'react';
import {
  Grid,
  WidgetContainer,
  Typography,
  G600,
  useTranslations,
  useHeroSnackbar,
  EditableContainer,
  DetailsElements,
} from '@uniqkey-frontend/shared-app';
import { GetPartnerByIdResponse } from '@uniqkey-backend-partner/api-client';
import ACLEnum from '../../../../../../enums/ACLEnum';
import {
  useGetPartnerById,
  useGetContactPersonById,
  useUpdateContactPerson,
} from '../../../../../../hooks/reactQuery';
import EditContactPersonDetailsModal, {
  IEditContactPersonDetailsModalReturnValue,
} from '../EditContactPersonDetailsModal';
import { useUser } from '../../../../../../contexts/UserContext';

interface IContactPersonDetailsWidgetProps {
  partnerId: string;
}

const MIN_CONTAINER_HEIGHT = 205;

const ContactPersonDetailsWidget = (props: IContactPersonDetailsWidgetProps) => {
  const { partnerId } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useHeroSnackbar();
  const { userCan } = useUser();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleEditModalOpen = useCallback(() => setIsEditModalOpen(true), []);
  const handleEditModalClose = useCallback(() => setIsEditModalOpen(false), []);

  const { data: partner } = useGetPartnerById(
    { partnerId },
  );

  const { contactPersonId } = partner ?? {} as GetPartnerByIdResponse;

  const {
    data: contactPerson,
    isLoading: isContactPersonLoading,
    isError,
  } = useGetContactPersonById(
    { contactPersonId },
    {
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
      },
    },
  );

  const { mutate, isLoading: isContactPersonUpdating } = useUpdateContactPerson({
    contactPersonId,
    useOptimisticUpdates: true,
  });

  const handleEditContactPersonDetails = useCallback((
    value: IEditContactPersonDetailsModalReturnValue,
  ) => {
    mutate(
      value,
      {
        onError: () => showError({ text: t('common.somethingWentWrong') }),
        onSuccess: () => {
          showSuccess({ text: t('partnerPage.toast.detailsUpdated') });
          handleEditModalClose();
        },
      },
    );
  }, [mutate, handleEditModalClose, showError, showSuccess, t]);

  const elements = useMemo(() => {
    const {
      name, email, phone,
    } = contactPerson ?? {};
    return {
      name, email, phone,
    };
  }, [contactPerson]);

  if (isError) {
    return null;
  }

  return (
    <WidgetContainer
      container
      p={0}
      isLoading={isContactPersonLoading}
      minHeight={MIN_CONTAINER_HEIGHT}
      withShadow
    >
      <EditableContainer
        container
        item
        p={3}
        rowGap={2}
        minHeight={MIN_CONTAINER_HEIGHT}
        onClick={handleEditModalOpen}
        disabled={!userCan(ACLEnum.PartnerEdit)}
      >
        <Grid container>
          <Typography variant="body3" color={G600}>
            {t('contactPersonDetailsWidget.title')}
          </Typography>
        </Grid>
        <DetailsElements
          hidden={!contactPerson}
          elements={elements}
          translationKey="contactPersonDetailsWidget"
          t={t}
        />
      </EditableContainer>
      {isEditModalOpen && (
      <EditContactPersonDetailsModal
        isOpen={isEditModalOpen}
        isLoading={isContactPersonUpdating}
        onSubmit={handleEditContactPersonDetails}
        onClose={handleEditModalClose}
        contactPerson={contactPerson!}
      />
      )}
    </WidgetContainer>
  );
};

export default memo(ContactPersonDetailsWidget);
