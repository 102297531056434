import {
  WidgetContainer,
  Grid,
  Typography,
  G900,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { memo } from 'react';
import { GetMoveInfoResponse } from '@uniqkey-backend-partner/api-client';
import MoveDetailsInfoWidgetItem from './MoveDetailsInfoWidgetItem';

interface IMoveDetailsInfoWidgetProps extends Omit<GetMoveInfoResponse, 'movedFromPartnerId'> {}

const MoveDetailsInfoWidget = (props: IMoveDetailsInfoWidgetProps) => {
  const {
    movedFromPartnerName,
    movedFromContactName,
    movedFromContactEmail,
    movedFromContactPhone,
  } = props;
  const { t } = useTranslations();

  return (
    <WidgetContainer
      container
      flexDirection="column"
      py={3}
      withShadow
      rowGap={2}
      flexWrap="nowrap"
      className="min-height-100-percent"
    >
      <Grid container>
        <Typography variant="subtitle1" color={G900}>
          {t('moveDetailsInfoWidget.title')}
        </Typography>
      </Grid>
      <Grid container columnGap={1} flexWrap="nowrap">
        <Grid container item xs={6} flexDirection="column" rowGap={2}>
          <MoveDetailsInfoWidgetItem
            title={t('moveDetailsInfoWidget.partnerName')}
            value={movedFromPartnerName}
          />
          <MoveDetailsInfoWidgetItem
            title={t('moveDetailsInfoWidget.contactName')}
            value={movedFromContactName}
          />
        </Grid>
        <Grid container item xs={6} flexDirection="column" rowGap={2}>
          <MoveDetailsInfoWidgetItem
            title={t('moveDetailsInfoWidget.contactEmail')}
            value={movedFromContactEmail}
          />
          <MoveDetailsInfoWidgetItem
            title={t('moveDetailsInfoWidget.contactPhone')}
            // TODO: REMOVE "!" WHEN BE WILL BE UPDATED
            value={movedFromContactPhone!}
          />
        </Grid>
      </Grid>
    </WidgetContainer>
  );
};

export default memo(MoveDetailsInfoWidget);
