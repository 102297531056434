import { useCallback, useState, useMemo } from 'react';
import {
  Grid,
  Panel,
  Box,
  Button,
  FilterButton,
  useTranslations,
  SearchableTextField,
  Tooltip,
  InviteUserIcon,
  useHeroSnackbar,
  usePopper,
  RenderIf,
  ISearchableTextFieldProps,
  IFilterButtonProps,
  Divider,
  ActionButton,
  MoveIcon,
  useMapKeyValueExtractor,
} from '@uniqkey-frontend/shared-app';
import {
  CreatePartnerRequest,
  MeResponse,
  PartnerType,
} from '@uniqkey-backend-partner/api-client';
import ACLEnum from '../../enums/ACLEnum';
import CreatePartnerModal from './components/CreatePartnerModal';
import usePartnersAPI from '../../hooks/usePartnersAPI';
import PartnersTable from '../../components/tables/PartnersTable';
import usePartnersTable, { IPartnersTableRow } from '../../hooks/tables/usePartnersTable';
import { useLanguages } from '../../hooks/reactQuery';
import PartnersListFilter, {
  IPartnersListFilterSubmitResult,
} from './components/PartnersListFilter';
import { useUser } from '../../contexts/UserContext';
import { logException } from '../../services/sentryService';
import PartnersTableWithFilter from '../../components/tables/PartnersTableWithFilter';
import MovePartnersModal, { IMovePartnersModalSubmitResult } from './components/MovePartnersModal';
import {
  isUniqkey, isAmbassador, isDistributor, isSales,
} from '../../helpers/partnerType';

const SUCH_FIRST_PARTNER_ALREADY_EXISTS_ERROR = 'Such_FirstPartner_already_exists';

const PartnersPage = () => {
  const { t } = useTranslations();
  const { createPartner, movePartners } = usePartnersAPI();
  const { showSuccess, showError } = useHeroSnackbar();
  const { currentUser, userCan } = useUser();
  const { partnerType, partnerId } = currentUser ?? {} as MeResponse;

  const [isCreatePartnerModalOpen, setIsCreatePartnerModalOpen] = useState(false);
  const [isCreatePartnerLoading, setIsCreatePartnerLoading] = useState(false);
  const [isMovePartnersModalOpen, setIsMovePartnersModalOpen] = useState(false);
  const [isMovePartnersLoading, setIsMovePartnersLoading] = useState(false);

  // preload for create partner modal
  useLanguages({ includeOnlyAllowedLanguages: false });

  const {
    selectedPartners,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetQuery,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps
  } = usePartnersTable({
    noDataMessageKey: 'partnersPage.noData',
  });

  const {
    values: selectedPartnersAsObjects, keys: selectedPartnersIds,
  } = useMapKeyValueExtractor<IPartnersTableRow>(selectedPartners);

  const {
    isMovePartnersDisabled,
    selectedPartnersType,
    moveTooltipTitle,
  } = useMemo(() => {
    if (!selectedPartnersAsObjects.length) {
      return {
        isMovePartnersDisabled: true,
        selectedPartnersType: null,
        moveTooltipTitle: t('movePartnersModal.defaultTooltip'),
      };
    }
    let disableMovePartners = false;
    let partnersType;
    let moveTitle = t('movePartnersModal.defaultTooltip');
    let hasAmbassador = false;
    let hasDistributor = false;
    let hasSales = false;
    selectedPartnersAsObjects.forEach((partner) => {
      if (isAmbassador(partner.type)) {
        hasAmbassador = true;
        partnersType = null;
      }
      if (isDistributor(partner.type)) {
        hasDistributor = true;
        partnersType = PartnerType.Distributor;
      }
      if (isSales(partner.type)) {
        hasSales = true;
        partnersType = PartnerType.Sales;
      }
    });

    disableMovePartners = hasAmbassador || (hasDistributor && hasSales);

    if (hasDistributor && hasSales) {
      moveTitle = t('movePartnersModal.disabledTooltipDifferentTier');
      partnersType = null;
    }

    if (hasAmbassador) {
      moveTitle = t('movePartnersModal.disabledTooltipAmbassador');
      partnersType = null;
    }

    return {
      isMovePartnersDisabled: disableMovePartners,
      selectedPartnersType: partnersType,
      moveTooltipTitle: moveTitle,
    };
  }, [selectedPartnersAsObjects, t]);

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const handleCreatePartnerModalOpen = useCallback(
    () => setIsCreatePartnerModalOpen(true),
    [],
  );

  const handleCreatePartnerModalClose = useCallback(
    () => setIsCreatePartnerModalOpen(false),
    [],
  );

  const handleMovePartnersModalOpen = useCallback(
    () => setIsMovePartnersModalOpen(true),
    [],
  );

  const handleMovePartnersModalClose = useCallback(
    () => setIsMovePartnersModalOpen(false),
    [],
  );

  const handleCreatePartner = useCallback(async (
    partner: CreatePartnerRequest,
  ) => {
    try {
      setIsCreatePartnerLoading(true);
      await createPartner(partner);
      showSuccess({
        text: t('createPartnerModal.partnerCreated'),
      });
      handleCreatePartnerModalClose();
      resetQuery();
    } catch (e: any) {
      let key = 'common.somethingWentWrong';
      if (e?.response?.data?.includes(SUCH_FIRST_PARTNER_ALREADY_EXISTS_ERROR)) {
        key = 'createPartnerModal.suchFirstPartnerAlreadyExistsError';
      }
      showError({ text: t(key) });
      logException(e, {
        message: 'PartnersPage/handleCreatePartner exception',
      });
    } finally {
      setIsCreatePartnerLoading(false);
    }
  }, [createPartner, handleCreatePartnerModalClose, resetQuery, showError, showSuccess, t]);

  const handleMovePartners = useCallback(async (
    value: IMovePartnersModalSubmitResult,
  ) => {
    try {
      const { targetPartnerId } = value;
      setIsMovePartnersLoading(true);
      await movePartners({
        sourcePartnerId: partnerId,
        partnerIds: selectedPartnersIds,
        targetPartnerId,
      });
      showSuccess({
        text: t('movePartnersModal.partnersMoved'),
      });
      handleMovePartnersModalClose();
      resetQuery();
      resetSelectedRows();
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'PartnersPage/handleMovePartners exception',
      });
    } finally {
      setIsMovePartnersLoading(false);
    }
  }, [
    handleMovePartnersModalClose,
    movePartners,
    partnerId,
    resetQuery,
    selectedPartnersIds,
    showError,
    showSuccess,
    t,
    resetSelectedRows,
  ]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((updatedValues: IPartnersListFilterSubmitResult) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  const { movedFromPartner, allMovedPartners } = filterValues ?? {};

  return (
    <Grid container flexDirection="column" className="min-height-100-percent">
      <Grid item mb={1}>
        <Panel>
          <Box p={1}>
            <Grid container justifyContent="space-between" alignItems="stretch">
              <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
                <Grid item>
                  <Tooltip title={t('common.filter')}>
                    <FilterButton
                      isFilterActive={isFilterActive}
                      numberOfActiveFilters={numberOfActiveFilters}
                      selected={isFilterOpen}
                      onChange={toggleIsFilterOpen}
                    />
                  </Tooltip>
                </Grid>
                <RenderIf condition={userCan(ACLEnum.PartnerMove) && isUniqkey(partnerType)}>
                  <Grid item my={0.5}>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item alignSelf="center">
                    <Tooltip title={moveTooltipTitle}>
                      <ActionButton
                        width={40}
                        height={40}
                        onClick={handleMovePartnersModalOpen}
                        disabled={isMovePartnersDisabled}
                      >
                        <MoveIcon />
                      </ActionButton>
                    </Tooltip>
                  </Grid>
                  <Grid item my={0.5}>
                    <Divider orientation="vertical" />
                  </Grid>
                </RenderIf>
              </Grid>
              <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
                <Grid item>
                  <SearchableTextField
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder={t('common.search')}
                  />
                </Grid>
                <RenderIf condition={userCan(ACLEnum.PartnerCreate)}>
                  <Box mr={3} />
                  <Grid item>
                    <Button
                      icon={<InviteUserIcon />}
                      onClick={handleCreatePartnerModalOpen}
                    >
                      {t('partnersPage.createPartnerButton')}
                    </Button>
                  </Grid>
                </RenderIf>
              </Grid>
            </Grid>
          </Box>
        </Panel>
      </Grid>
      <Grid item xs>
        <Panel>
          {(movedFromPartner?.value || allMovedPartners) ? (
            <PartnersTableWithFilter
              selectedPartners={selectedPartners}
              {...restTableProps}
            />
          ) : (
            <PartnersTable
              selectedPartners={selectedPartners}
              {...restTableProps}
            />
          )}
        </Panel>
      </Grid>
      {isCreatePartnerModalOpen && (
        <CreatePartnerModal
          isOpen={isCreatePartnerModalOpen}
          isLoading={isCreatePartnerLoading}
          onSubmit={handleCreatePartner}
          onClose={handleCreatePartnerModalClose}
          type={partnerType}
        />
      )}
      {isMovePartnersModalOpen && (
        <MovePartnersModal
          open={isMovePartnersModalOpen}
          onClose={handleMovePartnersModalClose}
          isLoading={isMovePartnersLoading}
          onSubmit={handleMovePartners}
          selectedPartnersAsObjects={selectedPartnersAsObjects}
          selectedPartnersType={selectedPartnersType!}
        />
      )}
      <PartnersListFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
        type={partnerType}
      />
    </Grid>
  );
};

export default PartnersPage;
